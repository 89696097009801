import React, { useState } from 'react';
import axios from 'axios'
import './Horoscope.css';

// Data Imports
import { initialdata } from "./Horoscopedata";
import { moonsignOptions } from "./Horoscopedata";
import { starSign } from "./Horoscopedata";
import { mangalikOptions } from "./Horoscopedata";
import { shaniOptions } from "./Horoscopedata";
import { hoursOptions } from "./Horoscopedata";
import { minutesOptions } from "./Horoscopedata";
import { secondsOptions } from "./Horoscopedata";

function Horoscope() {
    // Initial form object data
    let [data, setData] = useState(initialdata);
    let [active, setActivity] = useState(false);

    const inputChangeHandler = (e) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setActivity(!active);

        try {
            const response = await axios.post(
                "https://matrimonyadmin.hola9.com/api/Horoscopeapi/horoscopeList/",
                data
            );
            response && setActivity(!active);
            
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className='horoscope-container'>
            <h1 className='title-of-horoscope'>Horoscope</h1>
            <div className="horoscope-wraper">
                <div className="horoscope-input-wraper">
                    {/* Moon Sign */}
                    <div className="horoscope-input-element">
                        <lable className="inputs-lable">Moon sign</lable>
                        <select onChange={inputChangeHandler} name="moon_sign" id="" className="inputs-select-tag">
                            <option value="">Select Moon Sign</option>
                            {
                                moonsignOptions.map(ele => {
                                    return <option value={ele}>{ele}</option>
                                })
                            }
                        </select>
                        <span className="horoscope-warning-tag">Please select a moon sign</span>
                    </div>
                    {/* Star Sign */}
                    <div className="horoscope-input-element">
                        <lable className="inputs-lable" >Star</lable>
                        <select onChange={inputChangeHandler} name="star" id="" className="inputs-select-tag">
                            <option value="">Select Star</option>
                            {
                                starSign.map(ele => {
                                    return <option value={ele}>{ele}</option>
                                })
                            }
                        </select>
                        <span className="horoscope-warning-tag">Please select star</span>
                    </div>
                    {/* Gotra */}
                    <div className="horoscope-input-element">
                        <lable className="inputs-lable">Gotra</lable>
                        <input onChange={inputChangeHandler} type='text' name="gotra" placeholder='Enter Gotra' id="" className="inputs-select-tag"></input>
                        <span className="horoscope-warning-tag">Plese Enter gotra</span>
                    </div>
                    {/* Mangalik */}
                    <div className="horoscope-input-element">
                        <lable className="inputs-lable">Mangalik</lable>
                        <select onChange={inputChangeHandler} name="manglik" id="" className="inputs-select-tag">
                            <option value="">Select Mangalik</option>
                            {
                                mangalikOptions.map(ele => {
                                    return <option value={ele}>{ele}</option>
                                })
                            }
                        </select>
                        <span className="horoscope-warning-tag">Please select mangalik</span>
                    </div>
                    {/* Shani */}
                    <div className="horoscope-input-element">
                        <lable className="inputs-lable">Shani</lable>
                        <select onChange={inputChangeHandler} name="shani" id="" className="inputs-select-tag">
                            <option value="">Select Shani</option>
                            {
                                shaniOptions.map(ele => {
                                    return <option value={ele}>{ele}</option>
                                })
                            }
                        </select>
                        <span className="horoscope-warning-tag">Please select shani</span>
                    </div>
                    {/* Horoscope Match */}
                    <div className="horoscope-input-element">
                        <lable className="inputs-lable">Horoscope Match</lable>
                        <select onChange={inputChangeHandler} name="hororscope_match" id="" className="inputs-select-tag">
                            <option value="">Select Match Preference</option>
                            <option value="Prefered">Prefered</option>
                            <option value="Not Prefered">Not Prefered</option>
                            <option value="Does not matter">Does not matter</option>
                        </select>
                        <span className="horoscope-warning-tag"></span>
                    </div>
                    {/* Birth Place */}
                    <div className="horoscope-input-element">
                        <lable className="inputs-lable">Place of Birth</lable>
                        <input onChange={inputChangeHandler} type='text' name="place_of_birth" id="" placeholder='Enter Place of Birth' className="inputs-select-tag"></input>
                        <span className="horoscope-warning-tag">Plese Enter place of birth</span>
                    </div>
                    {/* Birth Country */}
                    <div className="horoscope-input-element">
                        <lable className="inputs-lable">Country</lable>
                        <input type='text' onChange={inputChangeHandler} placeholder='Enter Country' name="place_of_country" id="" className="inputs-select-tag"></input>
                        <span className="horoscope-warning-tag">Entery the Country Name</span>
                    </div>
                    {/* Hour */}
                    <div className="horoscope-input-element">
                        <lable className="inputs-lable">Hours</lable>
                        <select onChange={inputChangeHandler} name="hours" id="" className="inputs-select-tag">
                            <option value="">Hours</option>
                            {
                                hoursOptions.map(ele => {
                                    return <option value={ele.value}>{ele.label}</option>
                                })
                            }
                        </select>
                        <span className="horoscope-warning-tag">Please select hour</span>
                    </div>
                    {/* Minutes */}
                    <div className="horoscope-input-element">
                        <lable className="inputs-lable">Minutes</lable>
                        <select onChange={inputChangeHandler} name="minutes" id="" className="inputs-select-tag">
                            <option value="">Minutes</option>
                            {
                                minutesOptions.map(ele => {
                                    return <option value={ele.value}>{ele.label}</option>
                                })
                            }
                        </select>
                        <span className="horoscope-warning-tag">Please select minutes</span>
                    </div>
                    {/* Seconds */}
                    <div className="horoscope-input-element">
                        <lable className="inputs-lable">Seconds</lable>
                        <select onChange={inputChangeHandler} name="seconds" id="" className="inputs-select-tag">
                            <option value="">Seconds</option>
                            {
                                secondsOptions.map(ele => {
                                    return <option value={ele.value}>{ele.label}</option>
                                })
                            }
                        </select>
                        <span className="horoscope-warning-tag">Please select seconds</span>
                    </div>
                    {/* AM / PM */}
                    <div className="horoscope-input-element">
                        <lable className="inputs-lable">AM/PM</lable>
                        <select onChange={inputChangeHandler} name="am_pm" id="" className="inputs-select-tag">
                            <option value="">AM/PM</option>
                            <option value="am">AM</option>
                            <option value="pm">PM</option>
                        </select>
                        <span className="horoscope-warning-tag ">Select AM/PM</span>
                    </div>
                </div>
                <div className="horoscope-button-wraper">
                    <button disabled={active} onClick={handleSubmit} className='horoscope-submit-button'>Submit</button>
                </div>
            </div>
        </div >
    )
}

export default Horoscope