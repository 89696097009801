import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files"; //external library
import PdfIcon from './pdf-icon.png';  // img for pdf files
import axios from 'axios';
import './UploadIdAndDocument.css'

const fileTypes = ["JPG", "PNG", "PDF"];

function UploadIdAndDocument() {
    // States Required to maintain the first two Inputs.
    const [idImg, setIdImg] = useState(null)
    const [passPortPic, setPassPortPic] = useState(null);

    // States Required to maintain the second two Inputs.
    const [DocFile, setDocFile] = useState(null);
    const [docPic, setDocPic] = useState(null);

    // Error object
    const [err, setErr] = useState(null);

    // for updating id state
    const handleChangeID = (file) => {
        setIdImg(file);
    };

    // for updating photo state
    const handleChangePassPortPhoto = (file) => {
        setPassPortPic(file);
    };

    // for updating document state
    const handleChangeDocument = (file) => {
        setDocFile(file);
    };

    // for updating doc suporting photo
    const handleChangeDocPhoto = (file) => {
        setDocPic(file);
    };

    const handleSubmit1 = e => {
        e.preventDefault();

        // Form submission for first 2 inputs
        if (e.target.className === "id-page-submit-button1") {
            if (!idImg || !passPortPic) {
                setErr('Please upload both ID proof and photo.');
                return;


            }
            const formDataToSend = new FormData();
            formDataToSend.append('id_proof', idImg);
            formDataToSend.append('passport_size_photo', passPortPic);

            axios.post('https://matrimonyadmin.hola9.com/api/crud/idproofapi/', formDataToSend)
                .then(response => {
                    // setLoading(false);
                    // setSubmitted(true);
                    console.log('Form submitted successfully!');
                    console.log('Response:', response.data);
                })
                .catch(error => {
                    // setLoading(false);
                    setErr('An error occurred while submitting the form. Please try again.');
                    console.error('Error submitting form:', error);
                });
        }

        // Form submission for second 2 inputs
        if (e.target.className === "id-page-submit-button2") {
            if (!idImg || !passPortPic) {
                setErr('Please upload both ID proof and photo.');
                return;

            }
            const formDataToSend = new FormData();
            formDataToSend.append('document_file', DocFile);
            formDataToSend.append('passport_size_photo_for_document', docPic);

            axios.post('https://matrimonyadmin.hola9.com/api/crud/idproofapi/', formDataToSend)
                .then(response => {
                    // setLoading(false);
                    // setSubmitted(true);
                    console.log('Form submitted successfully!');
                    console.log('Response:', response.data);
                })
                .catch(error => {
                    // setLoading(false);
                    setErr('An error occurred while submitting the form. Please try again.');
                    console.error('Error submitting form:', error);
                });
        }
    }

    return (
        <div className="parent-container">
            <h1 className="UploadIdAndDocument-title">ID Proof and Document Uploads</h1>
            {/* ID Proof and its supporting image */}
            <div className="id-proof-container">

                <h3 className="id-proof-title-tag">Upload ID proof</h3>

                <div className='id-proof-input-wrapper'>
                    <div className="file">
                        <label className='id-proofi-input-label'>Click to upload Id Proof</label>
                        <FileUploader handleChange={handleChangeID} name="id-file" types={fileTypes} />
                        {

                            idImg ? ((idImg.type === "application/pdf") ? (<div className="uploaded-file-preview">
                                <img className="file-src" src={PdfIcon} width={100} height={100} alt='file-pic' />
                            </div>) : (<div className="uploaded-file-preview">
                                <img className="file-src" src={URL.createObjectURL(idImg)} width={100} height={100} alt='file-pic' />
                            </div>)) : (<p className="alert-text">Please select a file.</p>)
                        }
                    </div>

                    <div className="file">

                        <label className='id-proofi-input-label'>Click to upload Photo</label>
                        <FileUploader handleChange={handleChangePassPortPhoto} name="pic-file" types={fileTypes} />
                        {
                            passPortPic ? ((passPortPic.type === "application/pdf") ? (<div className="uploaded-file-preview">
                                <img className="file-src" src={PdfIcon} width={100} height={100} alt='file-pic' />
                            </div>) : (<div className="uploaded-file-preview">
                                <img className="file-src" src={URL.createObjectURL(passPortPic)} width={100} height={100} alt='file-pic' />
                            </div>)) : (<p className="alert-text">Please select a file.</p>)
                        }

                    </div>

                </div>
                <div className="id-proof-submit-section">
                    <button onClick={handleSubmit1} type="submit" className="id-page-submit-button1">Submit</button>
                </div>
            </div>

            {/* Document and its supporting image */}
            <div className="id-proof-container">

                <h3 className="id-proof-title-tag">Upload Document</h3>

                <div className='id-proof-input-wrapper'>

                    <div className="file">
                        <FileUploader handleChange={handleChangeDocument} name="file" types={fileTypes} />
                        {

                            DocFile ? ((DocFile.type === "application/pdf") ? (<div className="uploaded-file-preview">
                                <img className="file-src" src={PdfIcon} width={100} height={100} alt='file-pic' />
                            </div>) : (<div className="uploaded-file-preview">
                                <img className="file-src" src={URL.createObjectURL(DocFile)} width={100} height={100} alt='file-pic' />
                            </div>)) : (<p className="alert-text">Please select a file.</p>)
                        }
                    </div>

                    <div className="file">
                        <FileUploader handleChange={handleChangeDocPhoto} name="file" types={fileTypes} />
                        {

                            docPic ? ((docPic.type === "application/pdf") ? (<div className="uploaded-file-preview">
                                <img className="file-src" src={PdfIcon} width={100} height={100} alt='file-pic' />
                            </div>) : (<div className="uploaded-file-preview">
                                <img className="file-src" src={URL.createObjectURL(docPic)} width={100} height={100} alt='file-pic' />
                            </div>)) : (<p className="alert-text">Please select a file.</p>)
                        }
                    </div>
                </div>
                <div className="id-proof-submit-section">
                    <button onClick={handleSubmit1} type="submit" className="id-page-submit-button2">Submit</button>
                </div>
            </div>
        </div>
    );
}

export default UploadIdAndDocument;