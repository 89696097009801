import React from "react";
import "./WhoConnectedMe.css";

const WhoConnectedMe = () => {
  return (
    <div>
      <div className="page-title">
        <p>Who Connected Me</p>
      </div>

      <div className="who-contacted-me-container">
        <h1 className="who-contacted-me-heading">Who Connected Me</h1>
        <div className="who-contacted-me-input-container">
          <input
            type="text"
            className="who-contacted-me-input"
            placeholder="Name"
          />
          <input
            type="text"
            className="who-contacted-me-input"
            placeholder="Profile ID"
          />
          <input
            type="text"
            className="who-contacted-me-input"
            placeholder="Viewed Date"
          />
        </div>
      </div>
    </div>
  );
};

export default WhoConnectedMe;
