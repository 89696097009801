import React from 'react'
import { FaMapMarkedAlt, FaGraduationCap, FaBriefcase } from "react-icons/fa";
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import './Diff_profile.css'

const Diff_profile = () => {
  const [showCity, setShowCity] = useState(false);
  const [showEdu, setShowEdu] = useState(false);
  const [showProf, setShowProf] = useState(false);

  const handleShowEdu = () => {
    setShowEdu(true)
  }
  const handleShowCity = () => {
    setShowCity(true)
  }
  const handleShowProf = () => {
    setShowProf(true)
  }

  const handleClose = () => {
    setShowCity(false)
    setShowEdu(false)
    setShowProf(false)
  };

  return (
    <div className='diff-profile-page'>

      <div className='diff-profile-head'>
        <h1 className='diff-profile-card'>Explore Different Profiles</h1>
      </div>

      <div className='diff-profile-icons'>


        <div className='diff-profiles' onClick={handleShowCity} >
          <FaMapMarkedAlt className='Diff-profile-mapicon' style={{ color: "red" }} />
          <p className='diff-profile-icon-name' style={{ fontSize: "15px", fontWeight: "600" }}> Search By City</p>
        </div>

        <Modal show={showCity} onHide={handleClose} className='diff-profile-educationmodal' >
          <Modal.Header closeButton className='diff-profile-educationmodal-header'>
            <Modal.Title>Search By City</Modal.Title>
          </Modal.Header >
          <Modal.Body className='diff-profile-modal-body'>
            <Form className='diff-profile-educationicon-popup' >
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label style={{ color: 'cadetblue', fontSize: 'large', fontWeight: 'bold' }}>Search By City</Form.Label>
                <Form.Control className='diff-profile-education-search mb-3'
                  type="text"
                  placeholder="Search Country"
                  autoFocus
                />
                <Form.Control className='diff-profile-education-search mb-3'
                  type="text"
                  placeholder="Search State"
                  autoFocus
                />
                <Form.Control className='diff-profile-education-search mb-3'
                  type="text"
                  placeholder="Search City"
                  autoFocus
                />
              </Form.Group>
              <button input type='search' className='diff-profile-education-searchbtn'>Search</button>
            </Form>
          </Modal.Body>
          <Modal.Footer className='diff-profile-modal-footer'>
          </Modal.Footer>
        </Modal>

        <div className='diff-profiles' onClick={handleShowEdu}>
          <FaGraduationCap className='Diff-profile-educationicon' style={{ color: "red" }} />
          <p className='diff-profile-icon-name' style={{ fontSize: "15px", fontWeight: "600" }}>Search By Education</p>
        </div>

        <Modal show={showEdu} onHide={handleClose} className='diff-profile-educationmodal' >
          <Modal.Header closeButton className='diff-profile-educationmodal-header'>
            <Modal.Title>Search By Education</Modal.Title>
          </Modal.Header >
          <Modal.Body className='diff-profile-modal-body'>
            <Form className='diff-profile-educationicon-popup' >
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label style={{ color: 'cadetblue', fontSize: 'large', fontWeight: 'bold' }}>Search By Education</Form.Label>
                <Form.Control className='diff-profile-education-search'
                  type="text"
                  placeholder="Search Education"
                  autoFocus
                />
              </Form.Group>
              <button input type='search' className='diff-profile-education-searchbtn'>Search</button>
            </Form>
          </Modal.Body>
          <Modal.Footer className='diff-profile-modal-footer'>

            {/* <Button className='diff-profile-closebtn'  variant="secondary" onClick={handleClose}>
            Close
          </Button> */}

          </Modal.Footer>
        </Modal>



        <div className='diff-profiles' onClick={handleShowProf} >
          <FaBriefcase className='Diff-profile-professionicon' style={{ color: "red" }} />
          <p className='diff-profile-icon-name' style={{ fontSize: "15px", fontWeight: "600" }}>Search By Profession</p>
        </div>

        <Modal show={showProf} onHide={handleClose} className='diff-profile-educationmodal' >
          <Modal.Header closeButton className='diff-profile-educationmodal-header'>
            <Modal.Title>Search By Profession</Modal.Title>
          </Modal.Header >
          <Modal.Body className='diff-profile-modal-body'>
            <Form className='diff-profile-educationicon-popup' >
              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                <Form.Label style={{ color: 'cadetblue', fontSize: 'large', fontWeight: 'bold' }}>Search By Profession</Form.Label>
                <Form.Control className='diff-profile-education-search'
                  type="text"
                  placeholder="Search profession"
                  autoFocus
                />
              </Form.Group>
              <button input type='search' className='diff-profile-education-searchbtn'>Search</button>
            </Form>
          </Modal.Body>
          <Modal.Footer className='diff-profile-modal-footer'>

            {/* <Button className='diff-profile-closebtn'  variant="secondary" onClick={handleClose}>
            Close
          </Button> */}

          </Modal.Footer>
        </Modal>

      </div>

    </div>
  )
}

export default Diff_profile
