import React, { useState } from 'react';
import './HabitsForm.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import HobbyDetails from '../UserDashBoard/HobbyDetails';

const HabitsForm = () => {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  // State variables to hold habit values
  const [smoking, setSmoking] = useState('');
  const [drinking, setDrinking] = useState('');
  const [exercise, setExercise] = useState('');
  const [eating, setEating] = useState('');
  const [sleeping, setSleeping] = useState('');
  const[formErrors, setFormError] = useState({})
  const [formdata,setformdata] = useState({
    smokinghabit: '',
    drinking: '',
    exercise: '',
    eating: '',
    sleeping: '',
  });
  const validate=()=>{
    let errors= {};
    if(!smoking){
      errors.smoking='please select habit';
    }
    if(!drinking) errors.drinking = 'select habit';
    if(!exercise) errors.exercise = 'select habit';
    if(!eating) errors.eating = 'select habit';
    if(!sleeping) errors.sleeping = 'select habit';

    setFormError(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const habitsData = {
        smoking,
        drinking,
        exercise,
        eating,
        sleeping,
      };
      console.log('Form data submitted:', habitsData);

      // Send form data to the server
      axios.post('https://matrimonyadmin.hola9.com/api/profileapi/habits/', habitsData)
        .then(response => {
          console.log('Habits submitted successfully:', response.data);
          // Handle success case
        })
        .catch(error => {
          console.error('Error submitting habits:', error);
          // Handle error case
        });
    }
  };



  return (
    <div>

    <h2 className='habits'>Habits Details</h2>
    <button onClick={goBack} className='back-button-pay'><i class="ri-arrow-left-line"></i></button>

    <form onSubmit={handleSubmit}>
        <div className="habits-form-container">
      
      {/* Smoking habits */}
      <div>
      <label className='habit-label'><h4 className='habit4'>Smoking Habits:</h4></label>
        <div>
          <div className="smoking-form">
          <label className='habit-label-doc'>
            <span className='s1'> Non-smoker</span>
            <input
              className='radio-btn'
              type="radio"
              value="Non-smoker"
              checked={smoking === 'Non-smoker'}
              onChange={(e) => setSmoking(e.target.value)}
            />
          </label>
          </div>

          <div className="smoking-form">
          <label className='habit-label-doc'>
            <span className='s1'> Occasionally</span>
            <input
              className='radio-btn'
              type="radio"
              value="Occasionally"
              checked={smoking=== 'Occasionally'}
              onChange={(e) => setSmoking(e.target.value)}
            />
          </label>
          </div>

          <div className="smoking-form">
          <label className='habit-label-doc'>
            <span className='s1'> Regularly</span>
            <input
              className='radio-btn'
              type="radio"
              value="Regularly"
              checked={smoking === 'Regularly'}
              onChange={(e) => setSmoking(e.target.value)}
            />
          </label>
          </div>
          {formErrors.smoking && <p className="error">{formErrors.smoking}</p>}
        </div>
      </div>

      {/* {drinking} */}
      <div>
        <label className='habit-label'><h4 className='habit4'>Drinking Habits:</h4></label>
        <div>
        <div className="smoking-form">
          <label className='habit-label-doc'>
            <span className='s1'> Non-drinker</span>
            <input
              className='radio-btn'
              type="radio"
              value="Non-drinker"
              checked={drinking === 'Non-drinker'}
              onChange={(e) => setDrinking(e.target.value)}
            />
          </label>
          </div>
          <div className="smoking-form">
          <label className='habit-label-doc'>
            <span className='s1'> Occasionally</span>
            <input
              className='radio-btn'
              type="radio"
              value="Occasionally"
              checked={drinking=== 'Occasionally'}
              onChange={(e) => setDrinking(e.target.value)}
            />
          </label>
          </div>
          <div className="smoking-form">
          <label className='habit-label-doc'>
            <span className='s1'> Regularly</span>
            <input
              className='radio-btn'
              type="radio"
              value="Regularly"
              checked={drinking === 'Regularly'}
              onChange={(e) => setDrinking(e.target.value)}
            />
          </label>
          </div>
          {formErrors.drinking && <p className="error">{formErrors.drinking}</p>}
        </div>
      </div>

      {/* {exercise} */}

      <div>
        <label className='habit-label'><h4 className='habit4'>Exercise Habits:</h4></label>
        <div>
          
        <div className="smoking-form">
          <label className='habit-label-doc'>
            <span className='s1'>Sedentary</span>
            <input
              className='radio-btn'
              type="radio"
              value="Sedentary"
              checked={exercise === 'Sedentary'}
              onChange={(e) => setExercise(e.target.value)}
            />
          </label>
          </div>

          <div className="smoking-form">
          <label className='habit-label-doc'>
            <span className='s1'> Occasionally</span>
            <input
              className='radio-btn'
              type="radio"
              value="Occasionally"
              checked={exercise === 'Occasionally'}
              onChange={(e) => setExercise(e.target.value)}
            />
          </label>
          </div>

          <div className="smoking-form">
          <label className='habit-label-doc'>
            <span className='s1'> Regularly</span>
            <input
              className='radio-btn'
              type="radio"
              value="Regularly"
              checked={exercise === 'Regularly'}
              onChange={(e) => setExercise(e.target.value)}
            />
          </label>
          </div>
          {formErrors.exercise && <p className="error">{formErrors.exercise}</p>}
        </div>
      </div>

      {/* {eating} */}

      <div>
        <label className='habit-label'><h4 className='habit4'>Eating Habits:</h4></label>
        <div>

        <div className="smoking-form">
          <label className='habit-label-doc'>
            <span className='s1'> Vegetarian</span>
            <input
              className='radio-btn'
              type="radio"
              value="Vegetarian"
              checked={eating === 'Vegetarian'}
              onChange={(e) => setEating(e.target.value)}
            />
          </label>
          </div>

          <div className="smoking-form">
          <label className='habit-label-doc'>
            <span className='s1'>Non-vegetarian</span>
            <input
              className='radio-btn'
              type="radio"
              value="Non-vegetarian"
              checked={eating === 'Non-vegetarian'}
              onChange={(e) => setEating(e.target.value)}
            />
          </label>
          </div>

          <div className="smoking-form">
          <label className='habit-label-doc'>
            <span className='s1'>vegan</span>
            <input
              className='radio-btn'
              type="radio"
              value="vegan"
              checked={eating === 'vegan'}
              onChange={(e) => setEating(e.target.value)}
            />
          </label>
          </div>
          {formErrors.eating && <p className="error">{formErrors.eating}</p>}
        </div>
      </div>

      {/* {sleeping} */}

      <div>
        <label className='habit-label'><h4 className='habit4'>Sleeping Habits:</h4></label>
        <div>

        <div className="smoking-form">
          <label className='habit-label-doc'>
            <span className='s1'> Early bird</span>
            <input
              className='radio-btn'
              type="radio"
              value="Early bird"
              checked={sleeping === 'Early bird'}
              onChange={(e) => setSleeping(e.target.value)}
            />
          </label>
          </div>

          <div className="smoking-form">
          <label className='habit-label-doc'>
            <span className='s1'> Night owl</span>
            <input
              className='radio-btn'
              type="radio"
              value="Night owl"
              checked={sleeping=== 'Night owl'}
              onChange={(e) => setSleeping(e.target.value)}
            />
          </label>
          </div>

          <div className="smoking-form">
          <label className='habit-label-doc'>
            <span className='s1'> Balanced</span>
            <input
              className='radio-btn'
              type="radio"
              value="Balanced"
              checked={sleeping === 'Balanced'}
              onChange={(e) => setSleeping(e.target.value)}
            />
          </label>
          </div>
          
        </div>
        {formErrors.sleeping && <p className="error">{formErrors.sleeping}</p>}
      </div>

      {/* Similar structure for other habits */}

      {/* Submit button */}
      <button className='habit-button' >
        Submit
      </button>
      </div>
    </form>
      
      <div id='hobbyD'>
      <HobbyDetails/>
      </div>
    </div>
  );
};

export default HabitsForm;
