import React, { useState } from 'react';
import './CommonStyleSheetForSearchInputComponentss.css'

const GlobalSearch = () => {

  const [formError, setFormError] = useState({ occupation: '', caste: '', state: '' });

  const occupationOptions = ['Select Occupation', 'Science',
    'Architecture & Design',
    'Artists',
    'Animators & Web Designers',
    'Banking',
    'Insurance & Financial Services',
    'Beauty',
    'Fashion & Jewellery Designers',
    'Business Owner / Entrepreneur',
    'Civil Services / Law Enforcement',
    'Construction',
    'Customer Service/ Call Centre/BPO',
    'Defence',
    'Education/ Training',
    'Electronics',
    'Export/ Import',
    'Finance and Accounts',
    'Government Employee',
    'Health Care',
    'Hotels/ Restaurants',
    'Human Resource',
    'IT',
    'Legal',
    'Management / Corporate Professionals',
    'Manufacturing/ Engineering/ R&D',
    'Marketing and Communications',
    'Merchant Navy',
    'Non Working',
    'Oil & Gas',
    'Others',
    'Pharmaceutical/ Biotechnology',
    'Purchase/ Logistics/ Supply chain',
    'Real Estate',
    'Retail Chains',
    'Sales/ Business Development',
    'Telecom/ ISP',
    'Travel/ Airlines'];
  const casteOptions = ['Select caste', 'Kayastha',
    'Agarwal',
    'Rajput',
    'Khatri',
    'Arora',
    'Scheduled Caste',
    'Yadav',
    'Punjabi',
    'Bengali',
    'Nair',
    'Kshatriya',
    'Gupta',
    'Jat',
    '96K Kokanastha',
    'Ad Dharmi',
    'Adi Andhra',
    'Adi Dravida',
    'Adi Karnataka',
    'Agnikula Kshatriya',
    'Agri',
    'Ahom',
    'Ambalavasi',
    'Amil Sindhi',
    'Anglo Indian',
    'Araya',
    'Arekatica',
    'Arunthathiyar',
    'Arya Vysya',
    'Aryasamaj',
    'Ayyaraka',
    'Badaga',
    'Bahi',
    'Baibhand Sindhi',
    'Baidya',
    'Baishnab',
    'Baishya',
    'Balija',
    'Banik',
    'Baniya',
    'Banjara',
    'Barai',
    'Bari',
    'Barujibi',
    'Besta',
    'Bhandari',
    'Bhanusali Sindhi',
    'Bhatia',
    'Bhatia Sindhi',
    'Bhatraju',
    'Bhavasar Kshatriya',
    'Bhavsar',
    'Bhovi',
    'Bhumihar Brahmin',
    'Billava',
    'Bishnoi/Vishnoi',
    'Boyer',
    'Brahmbatt',
    'Bunt',
    'Bunt (Shetty)',
    'Chambhar',
    'Chandraseniya Kayastha Prab',
    'Chandravanshi Kahar',
    'Chasa',
    'Chattada Sri Vaishnava',
    'Chaudary',
    'Chaurasia',
    'Chettiar',
    'Chhapru Sindhi',
    'Chhetri',
    'Chippolu (Mera)',
    'CKP',
    'Coorgi',
    'Dadu Sindhi',
    'Darji',
    'Dawoodi Bhora',
    'Devadiga',
    'Devandra Kula Vellalar',
    'Devang Koshthi',
    'Devanga',
    'Devar/Thevar/Mukkulathor',
    'Devendra Kula Vellalar',
    'Dhaneshawat Vaish',
    'Dhangar',
    'Dheevara',
    'Dhiman',
    'Dhoba',
    'Dhobi',
    'Dumal',
    'Dusadh (Paswan)',
    'Ediga',
    'Ezhava',
    'Ezhuthachan',
    'Gabit',
    'Ganda',
    'Gandla',
    'Ganiga',
    'Ganigashetty',
    'Garhwali',
    'Garhwali Rajput',
    'Gavali',
    'Gavara',
    'Gawali',
    'Ghisadi',
    'Ghumar',
    'Goala',
    'Goan',
    'Gomantak',
    'Gomantak Maratha',
    'Gondhali',
    'Goswami',
    'Goswami/Gosavi Brahmin',
    'Goud',
    'Gounder',
    'Gowda',
    'Gramani',
    'Gudia',
    'Gujarati',
    'Gujjar',
    'Guptan',
    'Gurav',
    'Gurjar',
    'Hegde',
    'Himachali',
    'Hindu-Others',
    'Hugar (Jeer)',
    'Hyderabadi Sindhi',
    'Intercaste',
    'Irani',
    'Iyengar',
    'Iyer',
    'Jaalari',
    'Jaiswal',
    'Jandra',
    'Jangam',
    'Jatav',
    'Jeer',
    'Jogi (Nath)',
    'Kachara',
    'Kadava Patel',
    'Kahar',
    'Kaibarta',
    'Kalal',
    'Kalar',
    'Kalinga',
    'Kalinga Vysya',
    'Kalita',
    'Kalwar',
    'Kamboj',
    'Kamma',
    'Kannada Mogaveera',
    'Kansari',
    'Kapu',
    'Kapu Naidu',
    'Karana',
    'Karmakar',
    'Karuneegar',
    'Karuneekar',
    'Kasar',
    'Kashyap',
    'Katiya',
    'Keralite',
    'Khandayat',
    'Khandelwal',
    'Kharwar',
    'Khatik',
    'Khukhrain',
    'Koiri',
    'Kokanastha Maratha',
    'Koli',
    'Koli Mahadev',
    'Kongu Vellala Gounder',
    'Konkani',
    'Kori',
    'Koshti',
    'Kudumbi',
    'Kulal',
    'Kulalar',
    'Kulita',
    'Kumaoni',
    'Kumaoni Rajput',
    'Kumawat',
    'Kumbara',
    'Kumbhakar',
    'Kumbhar',
    'Kumhar',
    'Kummari',
    'Kunbi',
    'Kuravan',
    'Kurmi',
    'Kurmi Kshatriya',
    'Kuruba',
    'Kuruhina Shetty',
    'Kurumbar',
    'Kushwaha',
    'Kushwaha (Koiri)',
    'Kutchi',
    'Lambadi',
    'Lambani',
    'Larai Sindhi',
    'Larkana Sindhi',
    'Leva patel',
    'Leva Patidar',
    'Leva patil',
    'Lingayath',
    'Lodhi Rajput',
    'Lohana',
    'Lohana Sindhi',
    'Lohar',
    'Lubana',
    'Madiga',
    'Mahajan',
    'Mahar',
    'Maharashtrian',
    'Mahendra',
    'Maheshwari',
    'Mahishya',
    'Mahisya',
    'Majabi',
    'Mala',
    'Malayalee',
    'Malayalee Namboodiri',
    'Mali',
    'Mallah',
    'Mangalorean',
    'Manipuri',
    'Mannuru Kapu',
    'Mapila',
    'Maruthuvar',
    'Marvar',
    'Marwari',
    'Matang',
    'Mathur',
    'Maurya',
    'Meena',
    'Meenavar',
    'Mehra',
    'Menon',
    'Mera',
    'Meru',
    'Meru Darji',
    'Mochi',
    'Modak',
    'Mogaveera',
    'Monchi',
    'Mudaliar',
    'Mudaliar - Senguntha',
    'Mudaliar Arcot',
    'Mudaliar Saiva',
    'Mudaliyar',
    'Mudiraj',
    'Mukkulathor',
    'Mukulathur',
    'Munnuru Kapu',
    'Muthuraja', 'Naagavamsam',
    'Nadar',
    'Nagaralu',
    'Nai',
    'Naicker',
    'Naidu',
    'Naik',
    'Naik/Nayaka',
    'Naika',
    'Nair Vaniya',
    'Nair Vilakkithala',
    'Namasudra',
    'Nambiar',
    'Namboodiri',
    'Namosudra',
    'Napit',
    'Nath',
    'Nayaka',
    'Neeli',
    'Nepali',
    'Nhavi',
    'OBC/Barber/Naayee',
    'Oriya',
    'Oswal',
    'Otari',
    'Padmasali',
    'Padmashali',
    'Pal',
    'Panchal',
    'Pandaram',
    'Panicker',
    'Parkava Kulam',
    'Parsi',
    'Partraj',
    'Pasi',
    'Paswan',
    'Patel',
    'Patel Desai',
    'Patel Dodia',
    'Patel Kadva',
    'Patel Leva',
    'Pathare Prabhu',
    'Patil',
    'Patil Leva',
    'Patnaick',
    'Patnaick/Sistakaranam',
    'Patra',
    'Perika',
    'Pillai',
    'Poosala',
    'Porwal',
    'Prajapati',
    'Raigar',
    'Rajaka',
    'Rajastani',
    'Rajbhar',
    'Rajbonshi',
    'Rajput Rohella/Tank',
    'Ramdasia',
    'Ramgariah',
    'Ravidasia',
    'Rawat',
    'Reddy',
    'Relli',
    'Rohiri Sindhi', 'Sadgope',
    'Saha',
    'Sahiti Sindhi',
    'Sahu',
    'Saini',
    'Sakkhar Sindhi',
    'Saliya',
    'Saurashtra',
    'Savji',
    'Scheduled Tribe',
    'Sehwani Sindhi',
    'Senai Thalaivar',
    'Senguntha Mudaliyar',
    'Sepahia',
    'Setti Balija',
    'Settibalija',
    'Shah',
    'Shetty',
    'Shikarpuri Sindhi',
    'Shimpi',
    'Sindhi',
    'Sindhi-Amil',
    'Sindhi-Baibhand',
    'Sindhi-Bhanusali',
    'Sindhi-Bhatia',
    'Sindhi-Chhapru',
    'Sindhi-Dadu',
    'Sindhi-Hyderabadi',
    'Sindhi-Larai',
    'Sindhi-Larkana',
    'Sindhi-Lohana',
    'Sindhi-Rohiri',
    'Sindhi-Sahiti',
    'Sindhi-Sakkhar',
    'Sindhi-Sehwani',
    'Sindhi-Shikarpuri',
    'Sindhi-Thatai',
    'SKP',
    'Somvanshi',
    'Sonar',
    'Soni',
    'Sourashtra',
    'Sozhiya Vellalar',
    'Srisayana',
    'Srisayani',
    'Sugali (Naika)',
    'Sunari',
    'Sundhi',
    'Sutar',
    'Suthar',
    'Swakula Sali',
    'Swarnakar', 'Tamboli',
    'Tamil',
    'Tamil Yadava',
    'Tanti',
    'Tantubai',
    'Tantuway',
    'Telaga',
    'Teli',
    'Telugu',
    'Thakkar',
    'Thakore',
    'Thakur',
    'Thatai Sindhi',
    'Thigala',
    'Thiyya',
    'Tili',
    'Togata',
    'Tonk Kshatriya',
    'Turupu Kapu',
    'Udayar',
    'Uppara',
    'Urali Gounder',
    'Urs',
    'Vada Balija',
    'Vadagalai',
    'Vaddera',
    'Vaish',
    'Vaishnav',
    'Vaishnav Bhatia',
    'Vaishnav Vania',
    'Vaishnav Vanik',
    'Vaishnava',
    'Vaishya',
    'Vaishya Vani',
    'Valluvan',
    'Valmiki',
    'Vania',
    'Vaniya',
    'Vanjara',
    'Vanjari',
    'Vankar',
    'Vannar',
    'Vannia Kula Kshatriyar',
    'Vanniyar',
    'Variar',
    'Varshney',
    'Veera Saivam',
    'Veerashaiva',
    'Velaan/Vellalar',
    'Velama',
    'Vellalar',
    'Vellalar Devandra Kula',
    'Veluthedathu Nair',
    'Vettuva Gounder',
    'Vilakkithala Nair',
    'Vishwakarma',
    'Viswabrahmin',
    'Vokkaliga',
    'Vysya',
    'Yellapu',
    'Sonam',
    'Maratha'
  ];
  const stateOptions = ['Select State', 'Delhi',
    'Gujarat',
    'Haryana',
    'Himachal Pradesh',
    'Jammu and Kashmir',
    'Kerala',
    'Lakshadweep',
    'Maharashtra',
    'Manipur',
    'Meghalaya',
    'Karnataka',
    'Nagaland',
    'Orissa',
    'Pondicherry',
    'Punjab',
    'Rajasthan',
    'Tamil Nadu',
    'Tripura',
    'Bihar',
    'West Bengal',
    'Sikkim',
    'Mizoram',
    'Arunachal Pradesh',
    'Daman and Diu',
    'Goa',
    'Madhya Pradesh',
    'Uttar Pradesh',
    'Chhattisgarh',
    'Jharkhand',
    'Uttaranchal',
    'Out of India',
    'Andhra Pradesh'
  ];


  return (
    <div>
      <div className="common-container-search-components">
        <h1 className="common-search-title-tag">
          Global Search
        </h1>
        <div className="common-search-select-wraper">
          {/* All input wrapper */}
          <div className="select-tag-parent-wraper">

            <div className="select-tag-wraper">
              <label className='search-tag-lable'> Select Gender</label>
              <select
                className="search-section-select-tag"
                name="gendeer">
                <option value="" selected=""> Select Gender
                </option>
                <option value="Unmarried">Male</option>
                <option value="Divorced">Female</option>
              </select>
              <span style={{ color: "red" }}></span>
            </div>

            <div className="select-tag-wraper">
              <label className='search-tag-lable'> Age from</label>
              <select
                className="search-section-select-tag"
                autofocus=""
                name="Age_From"
                required=""
                tabIndex={5}
              >
                <option value="" selected="">
                  Select Age From
                </option>
                <option value={18}>18</option>
                <option value={19}>19</option>
                <option value={20}>20</option>
                <option value={21}>21</option>
                <option value={22}>22</option>
                <option value={23}>23</option>
                <option value={24}>24</option>
                <option value={25}>25</option>
                <option value={26}>26</option>
                <option value={27}>27</option>
                <option value={28}>28</option>
                <option value={29}>29</option>
                <option value={30}>30</option>
                <option value={31}>31</option>
                <option value={32}>32</option>
                <option value={33}>33</option>
                <option value={34}>34</option>
                <option value={35}>35</option>
                <option value={36}>36</option>
                <option value={37}>37</option>
                <option value={38}>38</option>
                <option value={39}>39</option>
                <option value={40}>40</option>
                <option value={41}>41</option>
                <option value={42}>42</option>
                <option value={43}>43</option>
                <option value={44}>44</option>
                <option value={45}>45</option>
                <option value={46}>46</option>
                <option value={47}>47</option>
                <option value={48}>48</option>
                <option value={49}>49</option>
                <option value={50}>50</option>
                <option value={51}>51</option>
                <option value={52}>52</option>
                <option value={53}>53</option>
                <option value={54}>54</option>
                <option value={55}>55</option>
                <option value={56}>56</option>
                <option value={57}>57</option>
                <option value={58}>58</option>
                <option value={59}>59</option>
                <option value={60}>60</option>
                <option value={61}>61</option>
                <option value={62}>62</option>
                <option value={63}>63</option>
                <option value={64}>64</option>
                <option value={65}>65</option>
              </select>
              <span style={{ color: "red" }}></span>
            </div>

            <div className="select-tag-wraper">
              <label className='search-tag-lable'> Age to </label>
              <select
                className="search-section-select-tag"
                name="Age_To"
                required=""
                tabIndex={6}
              >
                <option value="" selected="">
                  Select Age To
                </option>
                <option value={18}>18</option>
                <option value={19}>19</option>
                <option value={20}>20</option>
                <option value={21}>21</option>
                <option value={22}>22</option>
                <option value={23}>23</option>
                <option value={24}>24</option>
                <option value={25}>25</option>
                <option value={26}>26</option>
                <option value={27}>27</option>
                <option value={28}>28</option>
                <option value={29}>29</option>
                <option value={30}>30</option>
                <option value={31}>31</option>
                <option value={32}>32</option>
                <option value={33}>33</option>
                <option value={34}>34</option>
                <option value={35}>35</option>
                <option value={36}>36</option>
                <option value={37}>37</option>
                <option value={38}>38</option>
                <option value={39}>39</option>
                <option value={40}>40</option>
                <option value={41}>41</option>
                <option value={42}>42</option>
                <option value={43}>43</option>
                <option value={44}>44</option>
                <option value={45}>45</option>
                <option value={46}>46</option>
                <option value={47}>47</option>
                <option value={48}>48</option>
                <option value={49}>49</option>
                <option value={50}>50</option>
                <option value={51}>51</option>
                <option value={52}>52</option>
                <option value={53}>53</option>
                <option value={54}>54</option>
                <option value={55}>55</option>
                <option value={56}>56</option>
                <option value={57}>57</option>
                <option value={58}>58</option>
                <option value={59}>59</option>
                <option value={60}>60</option>
                <option value={61}>61</option>
                <option value={62}>62</option>
                <option value={63}>63</option>
                <option value={64}>64</option>
                <option value={65}>65</option>
              </select>
              <span style={{ color: "red" }}></span>
            </div>

            <div className="select-tag-wraper">
              <label className='search-tag-lable'>Select Material Status</label>
              <select className="search-section-select-tag" name="Height_From">
                <option value="" selected="">
                  Select Material Status
                </option>
                <option value="Unmaried">Unmarried</option>
                <option value="Divorced">Divorced</option>
                <option value="widower">widower </option>
              </select>
              <span style={{ color: "red" }}></span>
            </div>

            <div className="select-tag-wraper">
              <label className='search-tag-lable'>Religion</label>
              <select
                className="search-section-select-tag"
                name="Religion"
                required=""
                tabIndex={8}
              >
                <option value="" selected="">
                  select religion
                </option>
                <option value="Christian">Christian</option>
                <option value="Hindu">Hindu</option>
                <option value="hindu Barahsaini">
                  hindu Barahsaini
                </option>
                <option value="Inter-Religion">Inter-Religion</option>
                <option value="Jain">Jain</option>
                <option value="Muslim">Muslim</option>
                <option value="Sikh">Sikh</option>{" "}
              </select>
              <span style={{ color: "red" }}></span>
            </div>

            <div className="select-tag-wraper">
              <label className='search-tag-lable'> Education</label>
              <select
                className="search-section-select-tag"
                name="Education"
                required=""
                tabIndex={8}>
                <option value="" selected="">
                  Select Education
                </option>
                <option value="12th">12th</option>
                <option value="B.A. (Arts)		">B.A. (Arts) </option>
                <option value="B.Com (Commerce)">
                  B.Com (Commerce)
                </option>
                <option value="B.Sc (Science)">B.Sc (Science)</option>
                <option value="B.Arch (Architecture)	">
                  B.Arch (Architecture){" "}
                </option>
                <option value="B.Ed (Education)	">
                  B.Ed (Education){" "}
                </option>
                <option value="B.El.Ed (Elementary Education)	">
                  B.El.Ed (Elementary Education){" "}
                </option>
                <option value="B.Lib.Sc (Library Sciences)	">
                  B.Lib.Sc (Library Sciences){" "}
                </option>
                <option value="B.P.Ed. (Physical Education)">
                  B.P.Ed. (Physical Education)
                </option>
                <option value="B.Plan (Planning)	">
                  B.Plan (Planning){" "}
                </option>
                <option value="	Bachelor of Fashion Technology		">
                  {" "}
                  Bachelor of Fashion Technology{" "}
                </option>
                <option value="	BBA/BBM/BBS	"> BBA/BBM/BBS </option>
                <option value="BCA (Computer Application)		">
                  BCA (Computer Application){" "}
                </option>
                <option value="BE B.Tech (Engineering)">
                  BE B.Tech (Engineering)
                </option>
                <option value="BFA (Fine Arts)">BFA (Fine Arts)</option>
                <option value="BHM (Hotel Management)	">
                  BHM (Hotel Management){" "}
                </option>
                <option value="BL/LLB/BGL (Law)">
                  BL/LLB/BGL (Law)
                </option>
                <option value="BSW (Social Work)">
                  BSW (Social Work)
                </option>
                <option value="	B.Pharm (Pharmacy)		">
                  {" "}
                  B.Pharm (Pharmacy){" "}
                </option>
                <option value="	B.V.Sc. (Veterinary Science)">
                  {" "}
                  B.V.Sc. (Veterinary Science)
                </option>
                <option value="BDS (Dental Surgery)	">
                  BDS (Dental Surgery){" "}
                </option>
                <option value="BHMS (Homeopathy)">
                  BHMS (Homeopathy)
                </option>
                <option value="CA (Chartered Accountant)	">
                  CA (Chartered Accountant){" "}
                </option>
                <option value="CFA (Chartered Financial Analyst)">
                  CFA (Chartered Financial Analyst)
                </option>
                <option value="CS (Company Secretary)">
                  CS (Company Secretary)
                </option>
                <option value="ICWA">ICWA</option>
                <option value="Integrated PG">Integrated PG</option>
                <option value="Engineering">Engineering</option>
                <option value="Fashion/ Design	">
                  Fashion/ Design{" "}
                </option>
                <option value="Languages	">Languages </option>
                <option value="Pilot Licenses	">Pilot Licenses </option>
                <option value="M.Arch. (Architecture)	">
                  M.Arch. (Architecture){" "}
                </option>
                <option value="M.Ed. (Education)">
                  M.Ed. (Education)
                </option>
                <option value="M.Lib.Sc. (Library Sciences)">
                  M.Lib.Sc. (Library Sciences)
                </option>
                <option value="M.Plan. (Planning)">
                  M.Plan. (Planning)
                </option>
                <option value="Master of Fashion Technology">
                  Master of Fashion Technology
                </option>
                <option value="Master of Health Administration	">
                  Master of Health Administration{" "}
                </option>
                <option value="Master of Hospital Administration">
                  Master of Hospital Administration
                </option>
                <option value="MBA/PGDM">MBA/PGDM</option>
                <option value="MCA PGDCA part time	">
                  MCA PGDCA part time{" "}
                </option>
                <option value="MCA/PGDCA">MCA/PGDCA</option>
                <option value="ME/M.Tech/MS (Engg/Sciences)">
                  ME/M.Tech/MS (Engg/Sciences)
                </option>
                <option value="MFA (Fine Arts)	">
                  MFA (Fine Arts){" "}
                </option>
                <option value="ML/LLM (Law)	">ML/LLM (Law) </option>
                <option value="MSW (Social Work)	">
                  MSW (Social Work){" "}
                </option>
                <option value="PG Diploma">PG Diploma</option>
                <option value="M.Com. (Commerce)">
                  M.Com. (Commerce)
                </option>
                <option value="M.Sc. (Science)">M.Sc. (Science)</option>
                <option value="MA (Arts)">MA (Arts)</option>
                <option value="M.Pharm. (Pharmacy)	">
                  M.Pharm. (Pharmacy){" "}
                </option>
                <option value="M.V.Sc. (Veterinary Science)">
                  M.V.Sc. (Veterinary Science)
                </option>
                <option value="MBBS">MBBS</option>
                <option value="MD/ MS (Medicine)">
                  MD/ MS (Medicine)
                </option>
                <option value="MDS (Master of Dental Surgery)">
                  MDS (Master of Dental Surgery)
                </option>
                <option value="BPT (Physiotherapy">
                  BPT (Physiotherapy)
                </option>
                <option value="MPT (Physiotherapy)">
                  MPT (Physiotherapy)
                </option>
                <option value="M.Phil. (Philosophy)">
                  M.Phil. (Philosophy)
                </option>
                <option value="Ph.D. (Doctorate)	">
                  Ph.D. (Doctorate){" "}
                </option>
                <option value="Other Doctorate">Other Doctorate</option>
                <option value="Other Diploma">Other Diploma</option>
                <option value="Agriculture">Agriculture</option>
                <option value="10th">10th</option>{" "}
              </select>
              <span style={{ color: "red" }}></span>
            </div>

            <div className="select-tag-wraper">
              <label className="search-tag-lable">Occupation</label>
              <select
                className="search-section-select-tag"
                name="Education"
                required=""
                tabIndex={8}>
                {
                  occupationOptions.map(ele => {
                    return <option value={ele}>{ele}</option>
                  })
                }
              </select>
              <span style={{ color: 'red' }}>{formError.occupation}</span>
            </div>

            <div className="select-tag-wraper">
              <label className='search-tag-lable'> Photo Preference</label>
              <select
                className="search-section-select-tag"
                name="Photo_Preference"
                required=""
                tabIndex={8}
              >
                <option value="" selected="">
                  Any
                </option>
                <option value="Unmarried">With Photo</option>
                <option value="Divorced">Without Photo</option>
              </select>
              <span style={{ color: "red" }}></span>
            </div>

            <div className="select-tag-wraper">
              <label className='search-tag-lable'> Select Marital Status</label>
              <select
                className="search-section-select-tag"
                name="Marital_Status"
                required=""
                tabIndex={8}
              >
                <option value="" selected="">
                  Select Marital Status
                </option>
                <option value="Unmarried">Unmarried</option>
                <option value="Divorced">Divorced</option>
                <option value="Widower ">Widower</option>
              </select>
              <span style={{ color: "red" }}></span>
            </div>

            <div className="select-tag-wraper">
              <label className='search-tag-lable'>Height from</label>
              <select className="search-section-select-tag" name="Height_From">
                <option value="" selected="">
                  Select Height From
                </option>
                <option value={1}>4Ft </option>
                <option value={2}>4Ft 1 inch </option>
                <option value={3}>4Ft 2 inch </option>
                <option value={4}>4Ft 3 inch </option>
                <option value={5}>4Ft 4 inch </option>
                <option value={6}>4Ft 5 inch </option>
                <option value={7}>4Ft 6 inch </option>
                <option value={8}>4Ft 7 inch </option>
                <option value={9}>4Ft 8 inch </option>
                <option value={10}>4Ft 9 inch </option>
                <option value={11}>4Ft 10 inch </option>
                <option value={12}>4Ft 11 inch </option>
                <option value={13}>5Ft </option>
                <option value={14}>5Ft 1 inch </option>
                <option value={15}>5Ft 2 inch </option>
                <option value={16}>5Ft 3 inch </option>
                <option value={17}>5Ft 4 inch </option>
                <option value={18}>5Ft 5 inch </option>
                <option value={19}>5Ft 6 inch </option>
                <option value={20}>5Ft 7 inch </option>
                <option value={21}>5Ft 8 inch </option>
                <option value={22}>5Ft 9 inch </option>
                <option value={23}>5Ft 10 inch </option>
                <option value={24}>5Ft 11 inch </option>
                <option value={25}>6Ft </option>
                <option value={26}>6Ft 1 inch </option>
                <option value={27}>6Ft 2 inch </option>
                <option value={28}>6Ft 3 inch </option>
                <option value={29}>6Ft 4 inch </option>
                <option value={30}>6Ft 5 inch </option>
                <option value={31}>6Ft 6 inch </option>
                <option value={32}>6Ft 7 inch </option>
                <option value={33}>6Ft 8 inch </option>
                <option value={34}>6Ft 9 inch </option>
                <option value={35}>6Ft 10 inch </option>
                <option value={36}>6Ft 11 inch </option>
                <option value={37}>7Ft </option>
              </select>
              <span style={{ color: "red" }}></span>
            </div>

            <div className="select-tag-wraper">
              <label className='search-tag-lable'>Height to</label>
              <select
                className="search-section-select-tag"
                name="Height_To"
                tabIndex={8}
              >
                <option value="" selected="">
                  Select Height To
                </option>
                <option value={1}>4Ft </option>
                <option value={2}>4Ft 1 inch </option>
                <option value={3}>4Ft 2 inch </option>
                <option value={4}>4Ft 3 inch </option>
                <option value={5}>4Ft 4 inch </option>
                <option value={6}>4Ft 5 inch </option>
                <option value={7}>4Ft 6 inch </option>
                <option value={8}>4Ft 7 inch </option>
                <option value={9}>4Ft 8 inch </option>
                <option value={10}>4Ft 9 inch </option>
                <option value={11}>4Ft 10 inch </option>
                <option value={12}>4Ft 11 inch </option>
                <option value={13}>5Ft </option>
                <option value={14}>5Ft 1 inch </option>
                <option value={15}>5Ft 2 inch </option>
                <option value={16}>5Ft 3 inch </option>
                <option value={17}>5Ft 4 inch </option>
                <option value={18}>5Ft 5 inch </option>
                <option value={19}>5Ft 6 inch </option>
                <option value={20}>5Ft 7 inch </option>
                <option value={21}>5Ft 8 inch </option>
                <option value={22}>5Ft 9 inch </option>
                <option value={23}>5Ft 10 inch </option>
                <option value={24}>5Ft 11 inch </option>
                <option value={25}>6Ft </option>
                <option value={26}>6Ft 1 inch </option>
                <option value={27}>6Ft 2 inch </option>
                <option value={28}>6Ft 3 inch </option>
                <option value={29}>6Ft 4 inch </option>
                <option value={30}>6Ft 5 inch </option>
                <option value={31}>6Ft 6 inch </option>
                <option value={32}>6Ft 7 inch </option>
                <option value={33}>6Ft 8 inch </option>
                <option value={34}>6Ft 9 inch </option>
                <option value={35}>6Ft 10 inch </option>
                <option value={36}>6Ft 11 inch </option>
                <option value={37}>7Ft </option>
              </select>
              <span style={{ color: "red" }}></span>
            </div>

            <div className="select-tag-wraper">
              <label className='search-tag-lable'>Religion</label>
              <select
                className="search-section-select-tag"
                name="Religion"
                required=""
                tabIndex={8}
              >
                <option value="" selected="">
                  select religion
                </option>
                <option value="Christian">Christian</option>
                <option value="Hindu">Hindu</option>
                <option value="hindu Barahsaini">
                  hindu Barahsaini
                </option>
                <option value="Inter-Religion">Inter-Religion</option>
                <option value="Jain">Jain</option>
                <option value="Muslim">Muslim</option>
                <option value="Sikh">Sikh</option>{" "}
              </select>
              <span style={{ color: "red" }}></span>
            </div>

            <div className="select-tag-wraper">
              <label className="search-tag-lable">Caste</label>
              <select
                className="search-section-select-tag"
                name="Religion"
                required=""
                tabIndex={8}
              >
                {
                  casteOptions.map(ele => {
                    return <option value={ele}>{ele}</option>
                  })
                }
              </select>


              <span style={{ color: 'red' }}>{formError.caste}</span>
            </div>

            <div className="select-tag-wraper">
              <label className='search-tag-lable'> Complexion</label>
              <select
                className="search-section-select-tag"
                name="Complexion"
                tabIndex={8}
              >
                <option value="" selected="">
                  Select Complexion
                </option>

                <option value="Very Fair">Very Fair</option>
                <option value="Fair">Fair</option>
                <option value="Wheatish">Wheatish</option>
                <option value="Wheatish Medium">Wheatish Medium</option>
                <option value="Wheatish Brown">Wheatish Brown</option>
                <option value="Dark">Dark</option>
              </select>
              <span style={{ color: "red" }}></span>
            </div>

            <div className="select-tag-wraper">
              <label className='search-tag-lable'> Residency Status</label>
              <select
                className="search-section-select-tag"
                name="Residency_Status"
                required=""
                tabIndex={8}
              >
                <option value="" selected="">
                  Select Residency Status
                </option>
                <option value="Permanent Resident">
                  Permanent Resident
                </option>
                <option value="Student Visa">Student Visa</option>
                <option value="Temporary Visa">Temporary Visa</option>
                <option value="Work Permit">Work Permit</option>{" "}
              </select>
              <span style={{ color: "red" }}></span>
            </div>

            <div className="select-tag-wraper">
              <label className='search-tag-lable'> Country</label>
              <select
                className="search-section-select-tag"
                name="Country"
                required=""
                tabIndex={8}
              >
                <option value="" selected="">
                  Select Country
                </option>
                <option value="India" selected="">
                  India
                </option>
              </select>
              <span style={{ color: "red" }}></span>

            </div>

            <div className="select-tag-wraper">
              <label className="search-tag-lable">State</label>
              <select
                className="search-section-select-tag"
                name="Religion"
                required=""
                tabIndex={8}
              >
                {
                  stateOptions.map(ele => {
                    return <option value={ele}>{ele}</option>
                  })
                }
              </select>
              <span style={{ color: 'red' }}>{formError.state}</span>
            </div>

          </div>
          <div className="global-search-button-wraper">
            <button className="search-section-button">Search</button>
          </div>
        </div>
      </div>
    </div >

  )
}

export default GlobalSearch;