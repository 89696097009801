import React, { useState } from 'react';
import "./CommonStyleSheetForSearchInputComponentss.css";

const SearchProfile = () => {
  const [formError, setFormError] = useState({ education: '', occupation: '' });

  const educationOptions = [
    { value: '', label: 'Select Education' },
    { value: '12th', label: '12th' },
    { value: 'B.A. (Arts)', label: 'B.A. (Arts)' },
    { value: 'B.Com (Commerce)', label: 'B.Com (Commerce)' },
    { value: 'B.Sc (Science)', label: 'B.Sc (Science)' },
    { value: 'B.Arch (Architecture)', label: 'B.Arch (Architecture)' },
    { value: 'B.Ed (Education)', label: 'B.Ed (Education)' },
    { value: 'B.El.Ed (Elementary Education)', label: 'B.El.Ed (Elementary Education)' },
    { value: 'B.Lib.Sc (Library Sciences)', label: 'B.Lib.Sc (Library Sciences)' },
    { value: 'B.P.Ed. (Physical Education)', label: 'B.P.Ed. (Physical Education)' },
    { value: 'B.Plan (Planning)', label: 'B.Plan (Planning)' },
    { value: 'Bachelor of Fashion Technology', label: 'Bachelor of Fashion Technology' },
    { value: 'BBA/BBM/BBS', label: 'BBA/BBM/BBS' },
    { value: 'BCA (Computer Application)', label: 'BCA (Computer Application)' },
    { value: 'BE B.Tech (Engineering)', label: 'BE B.Tech (Engineering)' },
    { value: 'BFA (Fine Arts)', label: 'BFA (Fine Arts)' },
    { value: 'BHM (Hotel Management)', label: 'BHM (Hotel Management)' },
    { value: 'BL/LLB/BGL (Law)', label: 'BL/LLB/BGL (Law)' },
    { value: 'BSW (Social Work)', label: 'BSW (Social Work)' },
    { value: 'B.Pharm (Pharmacy)', label: 'B.Pharm (Pharmacy)' },
    { value: 'B.V.Sc. (Veterinary Science)', label: 'B.V.Sc. (Veterinary Science)' },
    { value: 'BDS (Dental Surgery)', label: 'BDS (Dental Surgery)' },
    { value: 'BHMS (Homeopathy)', label: 'BHMS (Homeopathy)' },
    { value: 'CA (Chartered Accountant)', label: 'CA (Chartered Accountant)' },
    { value: 'CFA (Chartered Financial Analyst)', label: 'CFA (Chartered Financial Analyst)' },
    { value: 'CS (Company Secretary)', label: 'CS (Company Secretary)' },
    { value: 'ICWA', label: 'ICWA' },
    { value: 'Integrated PG', label: 'Integrated PG' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Fashion/ Design', label: 'Fashion/ Design' },
    { value: 'Languages', label: 'Languages' },
    { value: 'Pilot Licenses', label: 'Pilot Licenses' },
    { value: 'M.Arch. (Architecture)', label: 'M.Arch. (Architecture)' },
    { value: 'M.Ed. (Education)', label: 'M.Ed. (Education)' },
    { value: 'M.Lib.Sc. (Library Sciences)', label: 'M.Lib.Sc. (Library Sciences)' },
    { value: 'M.Plan. (Planning)', label: 'M.Plan. (Planning)' },
    { value: 'Master of Fashion Technology', label: 'Master of Fashion Technology' },
    { value: 'Master of Health Administration', label: 'Master of Health Administration' },
    { value: 'Master of Hospital Administration', label: 'Master of Hospital Administration' },
    { value: 'MBA/PGDM', label: 'MBA/PGDM' },
    { value: 'MCA PGDCA part time', label: 'MCA PGDCA part time' },
    { value: 'MCA/PGDCA', label: 'MCA/PGDCA' },
    { value: 'ME/M.Tech/MS (Engg/Sciences)', label: 'ME/M.Tech/MS (Engg/Sciences)' },
    { value: 'MFA (Fine Arts)', label: 'MFA (Fine Arts)' },
    { value: 'ML/LLM (Law)', label: 'ML/LLM (Law)' },
    { value: 'MSW (Social Work)', label: 'MSW (Social Work)' },
    { value: 'PG Diploma', label: 'PG Diploma' },
    { value: 'M.Com. (Commerce)', label: 'M.Com. (Commerce)' },
    { value: 'M.Sc. (Science)', label: 'M.Sc. (Science)' },
    { value: 'MA (Arts)', label: 'MA (Arts)' },
    { value: 'M.Pharm. (Pharmacy)', label: 'M.Pharm. (Pharmacy)' },
    { value: 'M.V.Sc. (Veterinary Science)', label: 'M.V.Sc. (Veterinary Science)' },
    { value: 'MBBS', label: 'MBBS' },
    { value: 'MD/ MS (Medicine)', label: 'MD/ MS (Medicine)' },
    { value: 'MDS (Master of Dental Surgery)', label: 'MDS (Master of Dental Surgery)' },
    { value: 'BPT (Physiotherapy)', label: 'BPT (Physiotherapy)' },
    { value: 'MPT (Physiotherapy)', label: 'MPT (Physiotherapy)' },
    { value: 'M.Phil. (Philosophy)', label: 'M.Phil. (Philosophy)' },
    { value: 'Ph.D. (Doctorate)', label: 'Ph.D. (Doctorate)' },
    { value: 'Other Doctorate', label: 'Other Doctorate' },
    { value: 'Other Diploma', label: 'Other Diploma' },
    { value: 'Agriculture', label: 'Agriculture' },
    { value: '10th', label: '10th' },
  ];

  const occupationOptions = [
    { value: '', label: 'Select Occupation' },
    { value: 'Science', label: 'Science' },
    { value: 'Architecture & Design', label: 'Architecture & Design' },
    { value: 'Artists', label: 'Artists' },
    { value: 'Animators & Web Designers', label: 'Animators & Web Designers' },
    { value: 'Banking', label: 'Banking' },
    { value: 'Insurance & Financial Services', label: 'Insurance & Financial Services' },
    { value: 'Beauty', label: 'Beauty' },
    { value: 'Fashion & Jewellery Designers', label: 'Fashion & Jewellery Designers' },
    { value: 'Business Owner / Entrepreneur', label: 'Business Owner / Entrepreneur' },
    { value: 'Civil Services / Law Enforcement', label: 'Civil Services / Law Enforcement' },
    { value: 'Construction', label: 'Construction' },
    { value: 'Customer Service/ Call Centre/BPO', label: 'Customer Service/ Call Centre/BPO' },
    { value: 'Defence', label: 'Defence' },
    { value: 'Education/ Training', label: 'Education/ Training' },
    { value: 'Electronics', label: 'Electronics' },
    { value: 'Export/ Import', label: 'Export/ Import' },
    { value: 'Finance and Accounts', label: 'Finance and Accounts' },
    { value: 'Government Employee', label: 'Government Employee' },
    { value: 'Health Care', label: 'Health Care' },
    { value: 'Hotels/ Restaurants', label: 'Hotels/ Restaurants' },
    { value: 'Human Resource', label: 'Human Resource' },
    { value: 'IT', label: 'IT' },
    { value: 'Legal', label: 'Legal' },
    { value: 'Management / Corporate Professionals', label: 'Management / Corporate Professionals' },
    { value: 'Manufacturing/ Engineering/ R&D', label: 'Manufacturing/ Engineering/ R&D' },
    { value: 'Marketing and Communications', label: 'Marketing and Communications' },
    { value: 'Merchant Navy', label: 'Merchant Navy' },
    { value: 'Non Working', label: 'Non Working' },
    { value: 'Oil & Gas', label: 'Oil & Gas' },
    { value: 'Others', label: 'Others' },
    { value: 'Pharmaceutical/ Biotechnology', label: 'Pharmaceutical/ Biotechnology' },
    { value: 'Purchase/ Logistics/ Supply chain', label: 'Purchase/ Logistics/ Supply chain' },
    { value: 'Real Estate', label: 'Real Estate' },
    { value: 'Retail Chains', label: 'Retail Chains' },
    { value: 'Sales/ Business Development', label: 'Sales/ Business Development' },
    { value: 'Telecom/ ISP', label: 'Telecom/ ISP' },
    { value: 'Travel/ Airlines', label: 'Travel/ Airlines' },
  ];

  return (
    <div className="common-container-search-components">
      <h1 className="common-search-title-tag">Search Profile</h1>
      <div className="common-search-select-wraper">

        <div className="select-tag-parent-wraper">
          <div className="select-tag-wraper">
            <label className="search-tag-lable"> Select Gender</label>
            <select
              className="search-section-select-tag"
              name="Select_Gender"
              required=""
              tabIndex={8}
            >
              <option value="" selected="">Select Gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            <span style={{ color: "red" }}></span>
          </div>
          <div className="select-tag-wraper">
            <label className="search-tag-lable"> Age from</label>
            <select
              className="search-section-select-tag valid-SP"
              autofocus=""
              name="Age_From"
              required=""
              tabIndex={5}
            >
              <option value="" selected="">
                Select Age From
              </option>
              <option value={18}>18</option>
              <option value={19}>19</option>
              <option value={20}>20</option>
              <option value={21}>21</option>
              <option value={22}>22</option>
              <option value={23}>23</option>
              <option value={24}>24</option>
              <option value={25}>25</option>
              <option value={26}>26</option>
              <option value={27}>27</option>
              <option value={28}>28</option>
              <option value={29}>29</option>
              <option value={30}>30</option>
              <option value={31}>31</option>
              <option value={32}>32</option>
              <option value={33}>33</option>
              <option value={34}>34</option>
              <option value={35}>35</option>
              <option value={36}>36</option>
              <option value={37}>37</option>
              <option value={38}>38</option>
              <option value={39}>39</option>
              <option value={40}>40</option>
              <option value={41}>41</option>
              <option value={42}>42</option>
              <option value={43}>43</option>
              <option value={44}>44</option>
              <option value={45}>45</option>
              <option value={46}>46</option>
              <option value={47}>47</option>
              <option value={48}>48</option>
              <option value={49}>49</option>
              <option value={50}>50</option>
              <option value={51}>51</option>
              <option value={52}>52</option>
              <option value={53}>53</option>
              <option value={54}>54</option>
              <option value={55}>55</option>
              <option value={56}>56</option>
              <option value={57}>57</option>
              <option value={58}>58</option>
              <option value={59}>59</option>
              <option value={60}>60</option>
              <option value={61}>61</option>
              <option value={62}>62</option>
              <option value={63}>63</option>
              <option value={64}>64</option>
              <option value={65}>65</option>
            </select>
            <span style={{ color: "red" }}></span>
          </div>
          <div className="select-tag-wraper">
            <label className="search-tag-lable"> Age to </label>
            <select
              className="search-section-select-tag"
              name="Age_To"
              required=""
              tabIndex={6}
            >

              <option value="" selected="">
                Select Age To
              </option>
              <option value={18}>18</option>
              <option value={19}>19</option>
              <option value={20}>20</option>
              <option value={21}>21</option>
              <option value={22}>22</option>
              <option value={23}>23</option>
              <option value={24}>24</option>
              <option value={25}>25</option>
              <option value={26}>26</option>
              <option value={27}>27</option>
              <option value={28}>28</option>
              <option value={29}>29</option>
              <option value={30}>30</option>
              <option value={31}>31</option>
              <option value={32}>32</option>
              <option value={33}>33</option>
              <option value={34}>34</option>
              <option value={35}>35</option>
              <option value={36}>36</option>
              <option value={37}>37</option>
              <option value={38}>38</option>
              <option value={39}>39</option>
              <option value={40}>40</option>
              <option value={41}>41</option>
              <option value={42}>42</option>
              <option value={43}>43</option>
              <option value={44}>44</option>
              <option value={45}>45</option>
              <option value={46}>46</option>
              <option value={47}>47</option>
              <option value={48}>48</option>
              <option value={49}>49</option>
              <option value={50}>50</option>
              <option value={51}>51</option>
              <option value={52}>52</option>
              <option value={53}>53</option>
              <option value={54}>54</option>
              <option value={55}>55</option>
              <option value={56}>56</option>
              <option value={57}>57</option>
              <option value={58}>58</option>
              <option value={59}>59</option>
              <option value={60}>60</option>
              <option value={61}>61</option>
              <option value={62}>62</option>
              <option value={63}>63</option>
              <option value={64}>64</option>
              <option value={65}>65</option>
            </select>
            <span style={{ color: "red" }}></span>
          </div>
          <div className="select-tag-wraper">
            <label className="search-tag-lable"> Select Marital Status</label>
            <select
              className="search-section-select-tag"
              name="Marital_Status"
              required=""
              tabIndex={8}
            >
              <option value="" selected="">
                Select Marital Status
              </option>
              <option value="Unmarried">Unmarried</option>
              <option value="Divorced">Divorced</option>
              <option value="Widower ">Widower</option>
            </select>
            <span style={{ color: "red" }}></span>
          </div>
          <div className="col-lg-6-SP col-md-6-SP col-sm-6-SP form-group-SP">
            <label className="search-tag-lable">Religion</label>
            <select
              className="search-section-select-tag"
              name="Religion"
              required=""
              tabIndex={8}
            >
              <option value="" selected="">
                select religion
              </option>
              <option value="Any Religion">
                Any Religion
              </option>
              <option value="Christian">Christian</option>
              <option value="Hindu">Hindu</option>
              <option value="hindu Barahsaini">
                hindu Barahsaini
              </option>
              <option value="Inter-Religion">
                Inter-Religion
              </option>
              <option value="Jain">Jain</option>
              <option value="Muslim">Muslim</option>
              <option value="Sikh">Sikh</option>{" "}
            </select>
            <span style={{ color: "red" }}></span>
          </div>
          <div className="select-tag-wraper">
            <label className="search-tag-lable">Education</label>
            <select
              className="search-section-select-tag"
              name="Religion"
              required=""
              tabIndex={8}
            >
              {
                educationOptions.map(ele => {
                  return <option value={ele.value}>{ele.label}</option>
                })
              }
            </select>
            <span style={{ color: 'red' }}>{formError.education}</span>
          </div>
          <div className="select-tag-wraper">
            <label className="search-tag-lable">Occupation</label>
            <select
              className="search-section-select-tag"
              name="Religion"
              required=""
              tabIndex={8}
            >
              {
                occupationOptions.map(ele => {
                  return <option value={ele.value}>{ele.label}</option>
                })
              }
            </select>
            <span style={{ color: 'red' }}>{formError.occupation}</span>
          </div>
          <div className="select-tag-wraper">
            <label className="search-tag-lable"> Photo Preference</label>
            <select
              className="search-section-select-tag"
              name="Photo_Preference"
              required=""
              tabIndex={8}
            >
              <option value="" selected="">
                Any
              </option>
              <option value="Whith Photo">
                With Photo
              </option>
              <option value="Without Photo">
                Whithout Photo
              </option>
            </select>
            <span style={{ color: "red" }}></span>
          </div>
        </div>
        <div className="global-search-button-wraper">
          <button className="search-section-button">Search</button>
        </div>

      </div>
    </div>
  );
};

export default SearchProfile;
