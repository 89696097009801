import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./SignupStyle.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setNumber] = useState("");
  const [tc, setTc] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [error, setError] = useState("");
  const [verifyOtp, setVerifyOtp] = useState("");

  const [tempPassword, setTempPassword] = useState("");
  const [password, setNewPassword] = useState("");
  const [password2, setConfirmPassword] = useState("");
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const [openPassword, setOpenPassword] = useState(false);
  const [newUser, setNewUser] = useState(true);

  const [timer, setTimer] = useState(null); // State to manage timer

  const navigate = useNavigate();

  const emailRegex = (email) => {
    const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };

  const validateEmail = (email) => {
    let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    return regex.test(email);
  };

  const validatePhoneNumber = (number) => {
    let regex = /^\d{10}$/; // Ensures it is exactly 10 digits
    return regex.test(number);
  };

  const validatePassword = (password) => {
    return password.length >= 6; // Example: Password should be at least 6 characters long
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Clear previous error messages
    setError("");
    setErrorMsg("");

    // Validate email
    if (!email || !emailRegex(email)) {
      console.log("Invalid email format");
      setErrorMsg("Invalid email format");
      toast.error("Invalid email format");
      return;
    }

    // Validate name (disallow special characters and numbers)
    const nameRegex = /^[A-Za-z\s]+$/;
    if (!name || !nameRegex.test(name)) {
      console.log("Please enter a valid name");
      setError("Please enter a valid name");
      toast.error("Please enter a valid name");
      return;
    }

    // Validate phone number
    if (!phoneNumber || !validatePhoneNumber(phoneNumber)) {
      console.log("Please enter a valid 10-digit phone number");
      setError("Please enter a valid 10-digit phone number");
      toast.error("Please enter a valid 10-digit phone number");
      return;
    }

    // Validate password
    if (!password || password.length < 6) {
      console.log("Password must be at least 6 characters long");
      setError("Password must be at least 6 characters long");
      toast.error("Password must be at least 6 characters long");
      return;
    }

    // Validate confirm password
    if (password !== password2) {
      console.log("Passwords do not match");
      setError("Passwords do not match");
      toast.error("Passwords do not match");
      return;
    }

    // Validate terms and conditions
    if (!tc) {
      console.log("Please accept the terms and conditions");
      setError("Please accept the terms and conditions");
      toast.error("Please accept the terms and conditions");
      return;
    }

    // Check if the email and phone number already exist
    axios.get("https://madmin.hola9.com/api/user/profiless/").then(res => {
      const data1 = res.data;
      console.log(data1);
      data1.map((item) => {
        let email1 = item.email;
        let number = item.phoneNumber;
        console.log(email1);
        console.log(number);
        if (email === email1 && number === phoneNumber) {
          setNewUser(false);
          Swal.fire({
            title: "User exists!",
            text: "Please login!",
            icon: "error"
          });
        } else {
          setNewUser(true);
          console.log("login");
        }
      })
    });

    if (newUser) {
      let data = { email, name, phoneNumber, password, password2, tc };
      console.log(data);
      axios
        .post("https://madmin.hola9.com/api/user/register/", data)
        .then((res) => {
          console.log(res.data);
          if (res.status === 201) {
            console.log(res.data);
            localStorage.setItem("name", name);
            Swal.fire({
              title: "Sign in success!",
              text: "Please login!",
              icon: "success"
            });
            navigate("/Login");
          } else {
            toast.error("Failed to complete the process");
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error("Failed to complete the process");
        });
    }
  };

  // Function to handle password visibility toggle
  const handleShowPassword = () => {
    setShowPassword(true);
    clearTimeout(timer); // Clear the existing timer if any
    const newTimer = setTimeout(() => {
      setShowPassword(false);
    }, 2000); // Hide password after 2 seconds
    setTimer(newTimer); // Store the new timer
  };

  const handleConfirmPasswordShow = () => {
    setOpenPassword(true);
    clearTimeout(timer); // Clear the existing timer if any
    const newTimer = setTimeout(() => {
      setOpenPassword(false);
    }, 2000); // Hide confirm password after 2 seconds
    setTimer(newTimer); // Store the new timer
  };

  return (
    <>
      <ToastContainer />
      <div></div>
      <section className="newsletter-sections" style={{ backgroundImage: 'url(https://images.unsplash.com/photo-1707380659093-97e45913a9ea?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)', backgroundRepeat: 'no-repeat', backgroundSize: '100% 100%' }}>
        <div className="auto-containers" style={{ marginTop: '80px' }}>
          <div className="inner-column">
            <form method="post" action="#" id="contact-forms" name="matri" noValidate className="contact-forms glassmob">
              <div className="auto-containers texts" style={{ textAlign: "center", color: "black" }}>
                <h1 className="d-nones d-lg-blocks d-xl-blocks d-md-blocks" style={{ color: '#ec167f' }}>Signup</h1>
              </div>
              <div className="texts" style={{ textAlign: "center", color: "white", fontSize: '15px' }}>
                <h2 className="titleup">Matches Within Your Community</h2>
                Verified Profile | Safe and Secured | Entire Profile Description.
              </div>
              <br />
              <div className="form-groups mt-2" id="emailerror">
                <input className="agile-ltextSU" style={{ borderRadius: 5, color: 'white' }} type="email" name="email" tabIndex={3} placeholder="Enter Your E-mail" defaultValue="" onChange={(e) => setEmail(e.target.value)} value={email} required />
              </div>
              <div className="form-groups mt-2" id="nameerror">
                <input className="agile-ltextSU" style={{ borderRadius: 5 }} type="text" name="name" tabIndex={3} placeholder="Enter Your Name" defaultValue="" onChange={(e) => setName(e.target.value)} value={name} required />
              </div>
              <div className="form-groups mt-2" id="phoneerror">
                <input className="agile-ltextSU" style={{ borderRadius: 5 }} type="text" name="number" tabIndex={3} placeholder="Enter Your Phone Number" defaultValue="" onChange={(e) => setNumber(e.target.value)} value={phoneNumber} required />
              </div>
              <div className="form-groups mt-2" id="passerror">
                <input className="agile-ltextSU" style={{ borderRadius: 5, color: 'white' }} type={showPassword ? "text" : "password"} name="password" tabIndex={4} placeholder="Password" onChange={(e) => setNewPassword(e.target.value)} value={password} required />
                <span className="eye-icon">
                 
                </span>
              </div>
              <div className="form-groups mt-2" id="conpasserror">
                <input className="agile-ltextSU" style={{ borderRadius: 5, color: 'white' }} type={openPassword ? "text" : "password"} name="conpassword" tabIndex={4} placeholder="Confirm Password" onChange={(e) => setConfirmPassword(e.target.value)} value={password2} required />
                <span className="eye-icon">
                 
                </span>
              </div>
              <div className="form-groups mt-2" id="tcerror">
                <input type="checkbox" style={{ marginRight: '5px' }} name="tc" id="tc" checked={tc} onChange={(e) => setTc(e.target.checked)} />
                <label htmlFor="tc">I agree to the <Link to="/">terms and conditions</Link></label>
              </div>
              <div className="form-groups">
                <button type="submit" className="theme-buttons" onClick={handleSubmit}>Signup</button>
              </div>
              <div className="form-groups mt-3">
                Already have an account? <Link to="/Login">Login</Link>
              </div>
              {errorMsg && (
                <div className="form-groups mt-2" id="error">
                  <p>{errorMsg}</p>
                </div>
              )}
            </form>
          </div>
        </div>
      </section>
    </>
  );
};

export default Signup;
