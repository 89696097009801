  import React from 'react'
  import "./viewedprofile.css"
  import { pink } from '@mui/material/colors'

  const ViewedProfile = () => {
    return (
      <div >
              <div className="page-title">
          <p> Viewed Profile</p>
        </div>
      
        

          {/* <h1 style={{backgroundcolor:" #ec167f",color:" #ec167f",marginTop:"100px"}}>Oop'S</h1>
      <h4>Sorry Result Not Found</h4>
      <p>You have not yet connected profiles.</p> 
      <a href="smart_search" className="theme-btn btn-style-three" style={{marginLeft:'5px'}}>
      <span className="btn-title">Search</span>
    </a>
        <div className="anim-icons full-width" style={{marginTop:"100px"}}>
    <span
      className="icon icon-circle-blue wow fadeIn animated"
      style={{ visibility: "visible", animationName: "fa-spin",marginTop:"300px ",marginLeft:"-790px" }}
    />
    <span
      className="icon icon-dots wow fadeInleft animated"
      style={{ visibility: "visible",marginTop:"290px ",marginLeft:"-390px" }}
    />
    <span
              className="icon icon-line-1 wow fadeIn animated"
              style={{ visibility: "visible", animationName: "fa-spin",marginTop:"320px ",marginLeft:"400px" }}
            />

    <span
      className="icon icon-circle-1 wow zoomIn animated"
      style={{ visibility: "visible", animationName: "zoomIn" ,marginTop:"-150px ",marginLeft:"400px" }}
    />
  </div>

      */}

  <div className="View-Profile-Container">
        <h1 className="View-Profile-heading">Who Viewed My Profile</h1>
        <div className="View-Profile-input-container-view">
          <input type="text" className="View-Profile-input" placeholder="Name" style={{color: pink}}/>
          <input type="text" className="View-Profile-input" placeholder="Profile ID" />
          <input type="text" className="View-Profile-input" placeholder="Viewed Date" />
        </div>
        </div>
      
    
      </div>
    )
  }

  export default ViewedProfile;
