import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./basicdetailsStyle.css";
import "./Profiledetailstyle.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { casteOptions } from "./Basicdetailsdata";
import { requiredFields } from "./Basicdetailsdata";
import { validationMessages } from "./Basicdetailsdata";
import { initialData } from "./Basicdetailsdata";
import { genderOptions } from "./Basicdetailsdata";
import { bloodGroupOptions } from "./Basicdetailsdata";
import { religionOptions } from "./Basicdetailsdata";
import { profileCreatedByOptions } from "./Basicdetailsdata";
import { maritalStatusOptions } from "./Basicdetailsdata";
import { states } from "./Profiledetailsdata";
import { educationOptions } from "./Profiledetailsdata";
import { castes } from "./Profiledetailsdata";
import Profiledetails from "./Profiledetails";

const Basicdetails = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  const [data, setData] = useState(initialData);
  const [casteSearchTerm, setCasteSearchTerm] = useState("");
  const [selectedCaste, setSelectedCaste] = useState("");
  const casteDropdownRef = useRef(null);
  const [isCasteDropdownOpen, setIsCasteDropdownOpen] = useState(false);

  const handleCasteInputChange = ({ target: { value } }) =>
    setCasteSearchTerm(value);

  const handleSelectChange = (value) => {
    setSelectedCaste(value);
    setData({ ...data, your_caste: value });
    setIsCasteDropdownOpen(false);
  };

  const filteredCasteOptions = casteOptions.filter((option) =>
    option.label.toLowerCase().includes(casteSearchTerm.toLowerCase())
  );

  const toggleDropdown = () => setIsCasteDropdownOpen((prev) => !prev);

  const handleClickOutside = (event) => {
    if (!casteDropdownRef.current?.contains(event.target)) {
      setIsCasteDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const showToastErrorMessage = () => {
    toast.error("Please fill all fields", {
      position: toast.POSITION.TOP_CENTER,
    });
  };

  const [error, setError] = useState(null);
  const [formError, setFormError] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const ChangeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const validateForm = (data) => {
    let err = {};
    // Check if any required field is empty
    const isAnyFieldEmpty = requiredFields.some((field) => !data[field]);
    if (isAnyFieldEmpty) {
      showToastErrorMessage();
    }
    // Validate fields
    for (const [field, message] of Object.entries(validationMessages)) {
      if (field === "D_O_B" && calculateAge(data.D_O_B) < 21) {
        err[field] = message;
      } else if (data[field] === "" || data[field] === undefined) {
        err[field] = message;
      }
    }
    // Set form errors
    setFormError(err);
    // Return true if no errors, otherwise false
    return Object.keys(err).length === 0;
  };

  const calculateAge = (D_O_B) => {
    const birthDate = new Date(D_O_B);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    return today.setFullYear(1970) < birthDate.setFullYear(1970) ? --age : age;
  };

  const ageOptions = (Gender) =>
    Gender === "Male"
      ? Array.from({ length: 26 }, (_, i) => i + 21)
      : Gender === "Female"
      ? Array.from({ length: 28 }, (_, i) => i + 18)
      : [];

  const showData = async () => {
    try {
      if (!validateForm(data)) return;

      const {
        data: { id },
      } = await axios.post(
        "https://matrimonyadmin.hola9.com/api/BasicinformationPageapi/BasicInformationList/",
        data
      );

      setData((prev) => ({ ...prev, id }));
      setShowForm(true);
    } catch (error) {
      console.error(error);
    }
  };

  // profile data


  const [formData, setFormData] = useState({
    image: null,
    name: "",
    email: "",
    phone_number: "",
    address: "",
    state: "",
    city: "",
    country: "",
    zipcode: "",
    birth_date: "",
    age: "",
    gender: "",
    religion: "",
    caste: "",
    height: "",
    education: "",
    occupation: "",
    income: "",
    ageOptions: [],
  });

  const [dropdownOpen, setDropdownOpen] = useState({
    state: false,
    caste: false,
    education: false,
  });

  const [searchTerm, setSearchTerm] = useState({
    state: "",
    caste: "",
    education: "",
  });

  const inputhandleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "gender") {
      let ageOptions = [];
      if (value === "Male") {
        for (let i = 21; i <= 45; i++) {
          ageOptions.push(i);
        }
      } else if (value === "Female") {
        for (let i = 18; i <= 45; i++) {
          ageOptions.push(i);
        }
      }
      setFormData((prevState) => ({
        ...prevState,
        age: "",
        ageOptions: ageOptions,
      }));
    }
  };

  const handleImageChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      image: e.target.files[0],
    }));
  };

  const handleSubmitInput = async (e) => {
    e.preventDefault();

    for (const key in formData) {
      if (!formData[key]) {
        toast.error(`${key} is required`);
        return;
      }
    }

    try {
      const formDataToSend = new FormData();
      for (const key in formData) {
        formDataToSend.append(key, formData[key]);
      }
      formDataToSend.append("image", formData.image);

      const response = await axios.post(
        "https://matrimonyadmin.hola9.com/api/user/profiles/",
        formDataToSend,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
        
      );
      toast.success("Form submitted successfully!");
      setFormData({
        image: null,
        name: "",
        email: "",
        phone_number: "",
        address: "",
        state: "",
        city: "",
        country: "",
        zipcode: "",
        birth_date: "",
        age: "",
        gender: "",
        religion: "",
        caste: "",
        height: "",
        education: "",
        occupation: "",
        income: "",
        ageOptions: [],
      });
    } catch (error) {
      toast.error("Error submitting form. Please try again later.");
    }
  };

  const toggleDropdown1 = (dropdown) => {
    setDropdownOpen((prevState) => ({
      ...prevState,
      [dropdown]: !prevState[dropdown],
    }));
  };

  const handleSearchChange = (e, dropdown) => {
    const { value } = e.target;
    setSearchTerm((prevState) => ({
      ...prevState,
      [dropdown]: value,
    }));
  };

  const handleOptionSelect = (dropdown, value) => {
    setFormData((prevState) => ({
      ...prevState,
      [dropdown]: value,
    }));
    toggleDropdown1(dropdown);
  };
  return (
    <div>
      <>
        <div className="page-wrapperb">
          <section className="page-titleb" >
            <div className="auto-containerb">
              <h1 className="">
                Basic Details
              </h1>
            </div>
          </section>
          <button onClick={goBack} className="back-button-pay">
            <i class="ri-arrow-left-line"></i>
          </button>
          <section className="newsletter-sectionb contact-page-sectionb">
            <div className="auto-containerb">
              <div className="alertmesb"></div>
              <div className="rowb">
                <div className="form-columnb col-lg-9b col-md-12b col-sm-12b">
                  <div className="envelope-imageb" />
                  <div className="form-innerb">
                    <div className="contact-formb ">
                      <div className="rowb bgshadowwhite   mt-2b ">
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Name </label>
                          <input
                            type="text"
                            className="agile-ltextb"
                            placeholder="Enter your Name"
                            name="Name"
                            value={data.Name}
                            onChange={ChangeHandler}
                            readOnly=""
                          />
                          <span style={{ color: "red" }}>{formError.Name}</span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Surname </label>
                          <input
                            type="text"
                            className="agile-ltextb"
                            placeholder="Enter your Name"
                            name="Surname"
                            value={data.Surname}
                            onChange={ChangeHandler}
                            readOnly=""
                          />
                          <span style={{ color: "red" }}>
                            {formError.Surname}
                          </span>
                        </div>

                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Email </label>
                          <input
                            type="text"
                            placeholder="Email"
                            className="agile-ltextb"
                            name="Email"
                            value={data.Email}
                            onChange={ChangeHandler}
                          />
                          <span style={{ color: "red" }}>
                            {formError.Email}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Mobile Number </label>
                          <input
                            type="text"
                            name="Mobile_Number"
                            placeholder="Mobile Number"
                            className="agile-ltextb"
                            maxLength={14}
                            onChange={ChangeHandler}
                            value={data.Mobile_Number}
                            readOnly=""
                          />
                          <span style={{ color: "red" }}>
                            {formError.Mobile_Number}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb">Date Of Birth </label>
                          <input
                            type="datetime-local"
                            className="agile-ltextb"
                            placeholder="Date of birth"
                            name="D_O_B"
                            value={data.D_O_B}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="tgf31"
                          />
                          <span style={{ color: "red" }}>
                            {formError.D_O_B}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Age </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Enter your age"
                            name="Age"
                            onChange={ChangeHandler}
                            value={data.Age}
                          >
                            <option value="" selected="">
                              Select your Age
                            </option>
                            {ageOptions(data.Gender).map((age) => (
                              <option key={age} value={age}>
                                {age}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>{formError.Age}</span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Height </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Select Height"
                            name="Height"
                            onChange={ChangeHandler}
                            value={data.Height}
                            readOnly=""
                            fdprocessedid="jdvgst"
                          >
                            <option value="">Select Height</option>
                            {Array.from({ length: 37 }, (_, i) => {
                              const feet = Math.floor(i / 12) + 4;
                              const inches = i % 12;
                              const label =
                                inches === 0
                                  ? `${feet}Ft`
                                  : `${feet}Ft ${inches} inch`;
                              return (
                                <option key={i} value={i + 1}>
                                  {label}
                                </option>
                              );
                            })}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.Height}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Blood_Group </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Blood_Group "
                            name="Blood_Group"
                            value={data.Blood_Group}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="bwswso"
                          >
                            <option value="">Select Your Blood Group</option>
                            {bloodGroupOptions.map((group) => (
                              <option key={group} value={group.toLowerCase()}>
                                {group}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.Gender}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Gender </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Gender "
                            name="Gender"
                            value={data.Gender}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="bwswso"
                          >
                            <option value="">Select Your Gender</option>
                            {genderOptions.map((gender) => (
                              <option key={gender} value={gender}>
                                {gender}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.Gender}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb">Religion </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Religion "
                            name="your_religion"
                            value={data.your_religion}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="0eqdt9"
                          >
                            <option value="">Select Religion</option>
                            {religionOptions.map((your_religion) => (
                              <option key={your_religion} value={your_religion}>
                                {your_religion}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.your_religion}
                          </span>
                        </div>

                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb">Profile Created By </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder=" Profile Created By"
                            name="profile_created_By"
                            value={data.profile_created_By}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="tgf31"
                          >
                            {profileCreatedByOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.profile_created_By}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Marital_Status </label>
                          <select
                            type="text"
                            className="agile-ltextb"
                            placeholder="Enter your Name"
                            name="marital_status"
                            value={data.marital_status}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="j85x88"
                          >
                            {maritalStatusOptions.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>
                            {formError.marital_status}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb">Caste</label>
                          <div
                            className="custom-dropdown-basic"
                            ref={casteDropdownRef}
                          >
                            <div
                              className="selected-option-basic"
                              onClick={toggleDropdown}
                            >
                              {selectedCaste ? selectedCaste : "Select Caste"}
                            </div>
                            {isCasteDropdownOpen && (
                              <div className="dropdown-menu-basic">
                                <input
                                  type="text"
                                  className="custom-select-box-basic"
                                  placeholder="Search Caste"
                                  value={casteSearchTerm}
                                  onChange={handleCasteInputChange}
                                  autoFocus
                                />
                                <div className="dropdown-options-basic">
                                  {filteredCasteOptions.map((option, index) => (
                                    <div
                                      key={index}
                                      className="dropdown-option-basic"
                                      onClick={() =>
                                        handleSelectChange(option.label)
                                      }
                                    >
                                      {option.label}
                                    </div>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                          <span style={{ color: "red" }}>
                            {formError.your_caste}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> Subcaste </label>
                          <input
                            type="text"
                            className="agile-ltextb"
                            placeholder="Subcaste"
                            name="sub_caste"
                            value={data.sub_caste}
                            onChange={ChangeHandler}
                            readOnly=""
                            fdprocessedid="gk0h8"
                          />
                          <span style={{ color: "red" }}>
                            {formError.sub_caste}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-5b col-sm-6b form-groupb">
                          <label className="labelb"> About Yourself</label>
                          <textarea
                            type="textarea"
                            className="agile-ltextba"
                            name="about_yourself"
                            value={data.about_yourself}
                            onChange={ChangeHandler}
                            placeholder="Say Something About You"
                            rows={4}
                            maxLength={250}
                            tabIndex={10}
                            defaultValue={""}
                          />
                          <span style={{ color: "red" }}>
                            {formError.about_yourself}
                          </span>
                        </div>
                        <div className="col-lg-3b col-md-6b col-sm-8b form-groupb">
                          {!showForm && (
                            <div className="buttonsubmitdetails">
                              {!isSubmitted && (
                                <button
                                  className="horoscope-submit-button"
                                  fdprocessedid="jqtd0p"
                                  onClick={showData}
                                >
                                  <span className="btn-title" tabIndex={17}>
                                    Submit
                                  </span>
                                </button>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      <ToastContainer style={{ marginTop: "100px" }} />
                      {error ? (
                        <div
                          className="theme-text -style-one mt-4"
                          style={{ color: "red" }}
                        >
                          <h4>{error}</h4>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
      {/* profile details */}
      <div>
      <div className="profile-wrappers">
        <section
          className="profile-title"
          style={{ backgroundColor: "#fff" }}
        >
          <div className="auto-container_profile">
            <h1 className="d-none-profile d-lg-block-profile d-xl-block-profile d-md-block-profile">
              Profile Details
            </h1>
          </div>
        </section>

        <section className="newsletter-section-profile contact-page-section-profile">
          <div className="auto-container-profile">
            <div className="alertmes-profile"></div>
            <div className="row-profile  clearfix-profile ">
              <div className="form-column-profile col-lg-9-profile col-md-12-profile col-sm-12-profile">
                <div className="envelope-image-profile" />
                <div className="form-inner-profile">
                  <div className="contact-form-profile">
                    <form
                      className="bgshadowwhite1"
                      onSubmit={handleSubmitInput}
                      enctype="multipart/form-data"
                    >
                      <div className="row-profile  clearfix-profile mt-2-profile">
                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Name: </label>
                          <input
                            type="text"
                            className="agile-ltext-profiles"
                            placeholder="Enter your Name"
                            name="name"
                            value={formData.name}
                            onChange={inputhandleChange}
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.name} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Email: </label>
                          <input
                            type="email"
                            placeholder="Email"
                            className="agile-ltext-profiles"
                            name="email"
                            value={formData.email}
                            onChange={inputhandleChange}
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.email} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile">
                            {" "}
                            Phone Number:{" "}
                          </label>
                          <input
                            type="text"
                            name="phone_number"
                            placeholder="Phone Number"
                            className="agile-ltext-profiles"
                            maxLength={14}
                            value={formData.phone_number}
                            onChange={inputhandleChange}
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.phone_number} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Address: </label>
                          <input
                            type="text"
                            className="agile-ltext-profiles"
                            placeholder="Enter your Address"
                            name="address"
                            value={formData.address}
                            onChange={inputhandleChange}
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.address} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> State: </label>
                          <div className="select-all">
                            <div className="custom-select-container">
                              <div
                                className="custom-select"
                                onClick={() => toggleDropdown1("state")}
                              >
                                {formData.state || "Select State"}
                              </div>
                              {dropdownOpen.state && (
                                <div className="custom-select-dropdown">
                                  <input
                                    type="text"
                                    className="custom-select-search"
                                    placeholder="Search state..."
                                    value={searchTerm.state}
                                    onChange={(e) =>
                                      handleSearchChange(e, "state")
                                    }
                                  />
                                  <div className="custom-select-options">
                                    {states
                                      .filter((state) =>
                                        state
                                          .toLowerCase()
                                          .includes(
                                            searchTerm.state.toLowerCase()
                                          )
                                      )
                                      .map((state, index) => (
                                        <div
                                          key={index}
                                          className="custom-select-option"
                                          onClick={() =>
                                            handleOptionSelect("state", state)
                                          }
                                        >
                                          {state}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> City :</label>
                          <input
                            type="text"
                            className="agile-ltext-profiles"
                            placeholder="Enter your city"
                            name="city"
                            value={formData.city}
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="j85x88"
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.city} */}
                          </span>
                        </div>
                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Country : </label>
                          <select
                            type="text"
                            className="agile-ltext-profiless"
                            placeholder="country "
                            name="country"
                            value={formData.country}
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="bwswso"
                          >
                            <option value="" selected="">
                              Select country
                            </option>
                            <option value="India">India</option>
                            <option value="Not India">Not India</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {/* {formError.country} */}
                          </span>
                        </div>
                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Zipcode :</label>
                          <input
                            type="text"
                            className="agile-ltext-profiles"
                            placeholder="Enter your zipcode"
                            name="zipcode"
                            value={formData.zipcode}
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="j85x88"
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.zipcode} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile ">
                            Date Of Birth :
                          </label>
                          <input
                            type="datetime-local"
                            className="agile-ltext-profiles"
                            placeholder="Date of birth"
                            name="birth_date"
                            value={formData.birth_date}
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="tgf31"
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.birth_date} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile "> Gender :</label>
                          <select
                            type="text"
                            className="agile-ltext-profilesss"
                            placeholder="Gender "
                            name="gender"
                            value={formData.gender}
                            onChange={inputhandleChange}
                            required
                            readOnly=""
                            fdprocessedid="bwswso"
                          >
                            <option value="" disabled>
                              Select Your Gender
                            </option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="Others">Others</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {/* {formError.gender} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile"> Age :</label>

                          <select
                            type="number"
                            className="agile-ltext-profilesss "
                            // placeholder="Enter your age"
                            name="age"
                            value={formData.age}
                            onChange={inputhandleChange}
                            placeholder="Age"
                            required
                          >
                            <option value="" disabled>
                              Select your Age
                            </option>
                            {formData.ageOptions &&
                              formData.ageOptions.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            {[...Array(28)].map((_, index) => {
                              const age = index + 18;
                              return (
                                <option key={age} value={age}>
                                  {age}
                                </option>
                              );
                            })}
                          </select>
                          <span style={{ color: "red" }}>
                            {/* {formError.age} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile">Religion : </label>
                          <select
                            type="text"
                            className="agile-ltext-profilesss"
                            placeholder="Religion "
                            name="religion"
                            value={formData.religion}
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="0eqdt9"
                          >
                            <option value="" selected="">
                              Select Religion
                            </option>
                            <option value="Christian">Any</option>
                            <option value="Christian">Christian</option>
                            <option value="Hindu">Hindu</option>
                            <option value="hindu Barahsaini">
                              hindu Barahsaini
                            </option>
                            <option value="Inter-Religion">
                              Inter-Religion
                            </option>
                            <option value="Jain">Jain</option>
                            <option value="Muslim">Muslim</option>
                            <option value="Sikh">Sikh</option>
                          </select>
                          <span style={{ color: "red" }}>
                            {/* {formError.religion} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Caste: </label>
                          <div className="select-allc">
                            <div className="custom-select-container">
                              <div
                                className="custom-select"
                                onClick={() => toggleDropdown1("caste")}
                              >
                                {formData.caste || "Select Caste"}
                              </div>
                              {dropdownOpen.caste && (
                                <div className="custom-select-dropdown">
                                  <input
                                    type="text"
                                    className="custom-select-search"
                                    placeholder="Search caste..."
                                    value={searchTerm.caste}
                                    onChange={(e) =>
                                      handleSearchChange(e, "caste")
                                    }
                                  />
                                  <div className="custom-select-options">
                                    {castes
                                      .filter((caste) =>
                                        caste
                                          .toLowerCase()
                                          .includes(
                                            searchTerm.caste.toLowerCase()
                                          )
                                      )
                                      .map((caste, index) => (
                                        <div
                                          key={index}
                                          className="custom-select-option"
                                          onClick={() =>
                                            handleOptionSelect("caste", caste)
                                          }
                                        >
                                          {caste}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3-profile  col-md-5-profile  col-sm-6-profile  form-group-profile ">
                          <label className="label-profile"> Height : </label>
                          <select
                            type="text"
                            className="agile-ltext-profilesss"
                            placeholder="Select Height"
                            name="height"
                            value={formData.height}
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="jdvgst"
                          >
                            <option value="">Select Height</option>
                            {[4, 5, 6, 7].map((feet) => [
                              <option key={feet * 12} value={feet * 12}>
                                {feet}Ft
                              </option>,
                              ...[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(
                                (inches) => (
                                  <option
                                    key={feet * 12 + inches}
                                    value={feet * 12 + inches}
                                  >
                                    {feet}Ft {inches} inch
                                  </option>
                                )
                              ),
                            ])}
                          </select>
                          <span style={{ color: "red" }}>
                            {/* {formError.height} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Education: </label>
                          <div className="select-all">
                            <div className="custom-select-container">
                              <div
                                className="custom-select"
                                onClick={() => toggleDropdown1("education")}
                              >
                                {formData.education || "Select Education"}
                              </div>
                              {dropdownOpen.education && (
                                <div className="custom-select-dropdown">
                                  <input
                                    type="text"
                                    className="custom-select-search"
                                    placeholder="Search education..."
                                    value={searchTerm.education}
                                    onChange={(e) =>
                                      handleSearchChange(e, "education")
                                    }
                                  />
                                  <div className="custom-select-options">
                                    {educationOptions
                                      .filter((education) =>
                                        education
                                          .toLowerCase()
                                          .includes(
                                            searchTerm.education.toLowerCase()
                                          )
                                      )
                                      .map((education, index) => (
                                        <div
                                          key={index}
                                          className="custom-select-option"
                                          onClick={() =>
                                            handleOptionSelect(
                                              "education",
                                              education
                                            )
                                          }
                                        >
                                          {education}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Occupation :</label>
                          <input
                            type="text"
                            className="agile-ltext-profiles"
                            placeholder="Enter  occupation"
                            name="occupation"
                            // style={{marginLeft:"10%"}}
                            value={formData.occupation}
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="j85x88"
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.occupation} */}
                          </span>
                        </div>

                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Income :</label>
                          <input
                            type="number"
                            className="agile-ltext-profiles"
                            placeholder="Enter your income"
                            name="income"
                            value={formData.income}
                            onChange={inputhandleChange}
                            readOnly=""
                            fdprocessedid="j85x88"
                          />
                          <span style={{ color: "red" }}>
                            {/* {formError.income} */}
                          </span>
                        </div>
                        <div className="col-lg-3-profile col-md-5-profile col-sm-6-profile form-group-profile">
                          <label className="label-profile"> Image: </label>
                          <input
                            type="file"
                            className="agile-ltext-profiles"
                            id="image"
                            onChange={handleImageChange}
                          />
                        </div>
                      </div>

                      <div className="col-lg-3-profile buttonsubmitdetails col-md-6-profile  col-sm-8-profile  form-group-profile ">
                        {/* <Link to="/"> */}
                        <button
                          type="submit"
                          className="horoscope-submit-button"
                          fdprocessedid="jqtd0p"
                          // onClick={showData}
                          // onClick={handleSubmitinput}
                        >
                          <span className="btn-title-profile" tabIndex={17}>
                            Submit
                          </span>
                        </button>
                        {/* </Link> */}
                      </div>
                    </form>
                    {/* </Form> */}
                    <ToastContainer style={{ marginTop: "100px" }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      </div>
    </div>
  );
};
export default Basicdetails;
