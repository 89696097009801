import React, { useState } from "react";
import "./NewDashboardStyle.css";
// import UserdashBoard from '../UserDashBoard/UserdashBoard'
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { ListItemIcon, ListItemText, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import FavoriteIcon from "@material-ui/icons/Favorite";
import SearchIcon from "@material-ui/icons/Search";
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import MessageIcon from "@material-ui/icons/Message";
import RateReviewIcon from "@material-ui/icons/RateReview";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { fontWeight } from "@mui/system";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faAngleDown,
  faPeoplePulling,
  faThumbsUp,
  fa1heart,
  faFaceFrown,
  faWandMagic,
  faSearch,
  faCog,
  faComments,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import {
  faCheck,
  faGlobe,
  faCheckCircle,
  faPeopleLine,
  faMap,
  faIdCardClip,
  faStop,
  faFloppyDisk,
  faAddressCard,
  faSpinner,
  faGear,
  faEject,
  faStreetView,
  faHeart,
  faKeyboard,
  faMinusCircle,
  faUsersViewfinder,
  faPerson,
  faPersonRifle,
  faQuestionCircle,
  faSignOut,
  faLink,
  faNetworkWired,
  faAddressBook,
  faUser,
  faUserCheck,
  faUserPlus,
  faAngleLeft,
  faBell,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
const NewDashboard = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isProfileSubMenuOpen, setIsProfileSubMenuOpen] = useState(false);
  const [isMatchSubMenuOpen, setIsMatchSubMenuOpen] = useState(false);
  const [isSearchSubMenuOpen, setIsSearchSubMenuOpen] = useState(false);
  const [isACSubMenuOpen, setIsACSubMenuOpen] = useState(false);
  const [isOASubMenuOpen, setOAIsSubMenuOpen] = useState(false);
  const [isMessageSubMenuOpen, setMessageIsSubMenuOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [ispopup, setIspopup] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const dropdowntoogle = () => {
    setIspopup(!ispopup);
  };
  const handleLogout = () => {
    // Handle logout logic here
  };
  const handleProfileClick = () => {
    setIsProfileSubMenuOpen(!isProfileSubMenuOpen);
  };
  const handleMatchClick = () => {
    setIsMatchSubMenuOpen(!isMatchSubMenuOpen);
  };
  const handleSearchClick = () => {
    setIsSearchSubMenuOpen(!isSearchSubMenuOpen);
  };
  const handleACClick = () => {
    setIsACSubMenuOpen(!isACSubMenuOpen);
  };
  const handleOAClick = () => {
    setOAIsSubMenuOpen(!isOASubMenuOpen);
  };
  const handleMessageClick = () => {
    setMessageIsSubMenuOpen(!isMessageSubMenuOpen);
  };
  const handleDrawerOpen = () => {
    setIsDrawerOpen(true);
  };
  const handleDrawerClose = () => {
    setIsDrawerOpen(false);
  };
  return (
    <div>
      <div style={{ width: "100%" }}>
        <header className="main-header-dashnav header-style-two-dashnav">
          <div className="main-box-dashnav">
            <div className="auto-container-dashnav clearfix-dashnav">
              <div className="logo-box-dashnav">
                <div className="logo-dashnav">
                  <Link to="/dashboard">
                    <img
                      src="https://cdn.cdnparenting.com/articles/2021/09/14191221/08938f86cbffaa4d3027c4c45067a2a8.webp"
                      alt=""
                      title=""
                      height={200}
                      width={70}
                      style={{ borderRadius: "50px" }}
                    />
                  </Link>
                </div>
              </div>
              {/*Nav Box*/}
              <div className="nav-outer-dashnav clearfix-dashnav">
                {/* Main Menu */}
                <nav className="main-menu-dashnav navbar-expand-md-dashnav navbar-light-dashnav">
                  <div className="navbar-header-dashnav"></div>

                  <div
                    className="collapse-dashnav navbar-collapse-dashnav clearfix-dashnav"
                    id="navbarSupportedContent"
                  >
                    <ul className="navigation-dashnav clearfix-dashnav">
                      <li className="current-dashnav dropdown-dashnav">
                        <Link
                          to="/dashboard"
                          style={{
                            color: "white",
                            fontSize: "18px",
                            fontWeight: "700px",
                          }}
                        >
                          Profiles
                        </Link>
                        <ul>
                          <li>
                            <Link to="/dashboard/MyProfilefile">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="16"
                                fill="currentColor"
                                class="bi bi-person-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                              </svg>
                              My profile
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/basicAndProfiledetails">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-info-circle-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                              </svg>
                              basic & profile Details
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/dashboard/Contactus">
                              <i class="ri-contacts-book-3-fill"></i>
                              Contact Details
                            </Link>
                          </li> */}
                          <li>
                            <Link to="/dashboard/Horoscope">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-yin-yang"
                                viewBox="0 0 16 16"
                              >
                                <path d="M9.167 4.5a1.167 1.167 0 1 1-2.334 0 1.167 1.167 0 0 1 2.334 0Z" />
                                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM1 8a7 7 0 0 1 7-7 3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 0 0 7 7 7 0 0 1-7-7Zm7 4.667a1.167 1.167 0 1 1 0-2.334 1.167 1.167 0 0 1 0 2.334Z" />
                              </svg>
                              HoroScope
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/dashboard/Profiledetails">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-phone-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z" />
                              </svg>
                              Profile Details
                            </Link>
                          </li> */}
                          <li>
                            <Link to="/dashboard/Education">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-mortarboard-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5Z" />
                                <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Z" />
                              </svg>
                              Education& Professional
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/Family">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-person-hearts"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566ZM9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z"
                                />
                              </svg>
                              Family Details
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/Prefrence">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-gender-ambiguous"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M11.5 1a.5.5 0 0 1 0-1h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-3.45 3.45A4 4 0 0 1 8.5 10.97V13H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V14H6a.5.5 0 0 1 0-1h1.5v-2.03a4 4 0 1 1 3.471-6.648L14.293 1H11.5zm-.997 4.346a3 3 0 1 0-5.006 3.309 3 3 0 0 0 5.006-3.31z"
                                />
                              </svg>
                              Partner Preferences
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/interestslink">
                              <i class="ri-pinterest-fill"></i> Interests
                            </Link>
                          </li>
                          <li></li>
                          <li>
                            <Link to="/dashboard/Compatability">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-journal-check"
                                viewBox="0 0 16 16"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                                />
                                <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                                <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                              </svg>
                              My Compatibility
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/dashboard/Usersetting">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-gear-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                              </svg>
                              Privacy Setting
                            </Link>
                          </li> */}
                          <li>
                            <Link to="/dashboard/HabitsFormandDetails">
                              <i class="ri-drinks-2-fill"></i> HabitsForm and details
                            </Link>
                          </li>
                          {/* <li>
                            <Link to="/dashboard/HobbyDetails">
                              <i class="ri-football-fill"></i> HobbyDetails
                            </Link>
                          </li> */}

                          {/* ID Proof 
                          <li>
                            <Link to="/dashboard/IdProof">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-person-vcard-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5ZM9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8Zm1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5Zm-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96c.026-.163.04-.33.04-.5ZM7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
                              </svg>
                              Upload ID Proof
                            </Link>
                          </li>
                          */}

                          {/* Upload Doc
                          <li>
                            <Link to="/dashboard/Uploaddocument">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-file-earmark-arrow-up-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707L6.354 9.854z" />
                              </svg>
                              Upload Document
                            </Link>
                          </li> */}

                          {/* ID and Doc in single Page */}

                          <li>
                            <Link to="/dashboard/UploadIdAndDocument">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-file-earmark-arrow-up-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707L6.354 9.854z" />
                              </svg>
                              Upload Document
                            </Link>
                          </li>

                          <li>
                            <Link to="/dashboard/Newchangepassword">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-file-lock-fill"
                                viewBox="0 0 16 16"
                              >
                                <path d="M7 6a1 1 0 0 1 2 0v1H7V6zM6 8.3c0-.042.02-.107.105-.175A.637.637 0 0 1 6.5 8h3a.64.64 0 0 1 .395.125c.085.068.105.133.105.175v2.4c0 .042-.02.107-.105.175A.637.637 0 0 1 9.5 11h-3a.637.637 0 0 1-.395-.125C6.02 10.807 6 10.742 6 10.7V8.3z" />
                                <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-2 6v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V8.3c0-.627.46-1.058 1-1.224V6a2 2 0 1 1 4 0z" />
                              </svg>
                              Change Password
                            </Link>
                          </li>
                        </ul>
                        <div className="dropdown-btn-dashnav">
                          <span className="fa fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown-dashnav">
                        <Link
                          to="#"
                          style={{
                            color: "white",
                            fontSize: "18px",
                            fontWeight: "700px",
                          }}
                        >
                          Matches
                        </Link>
                        <ul>
                          <li>
                            <Link to="/dashboard/LatestMatches">
                              <span style={{ marginLeft: "25px" }}></span>{" "}
                              <FontAwesomeIcon
                                icon={faWandMagic}
                                style={{ fontSize: "20px", marginRight: "5px" }}
                              />
                              Latest Matches
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/MutalMatches">
                              <span style={{ marginLeft: "20px" }}></span>
                              <FontAwesomeIcon
                                icon={faHeart}
                                style={{ marginRight: "5px" }}
                              />
                              Mutual Matches
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/CompatibulityMatches">
                              <span style={{ marginLeft: "20px" }}></span>
                              <FontAwesomeIcon
                                icon={faPeoplePulling}
                                style={{ marginRight: "5px" }}
                              />
                              Compatibility Matches
                            </Link>
                          </li>
                        </ul>
                      </li>

                      <li className="dropdown-dashnav">
                        <Link
                          to="/"
                          style={{
                            color: "white",
                            fontSize: "18px",
                            fontWeight: "700px",
                          }}
                        >
                          Search
                        </Link>
                        <ul>
                          <li>
                            <Link to="/dashboard/SearchProfile">
                              <span style={{ marginLeft: "20px" }}></span>
                              <FontAwesomeIcon
                                icon={faSearch}
                                style={{ marginRight: "5px" }}
                              />
                              Regular Search
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/AdvanceSearch">
                              <span style={{ marginLeft: "20px" }}></span>
                              <FontAwesomeIcon
                                icon={faSearch}
                                style={{ marginRight: "5px" }}
                              />
                              Advance Search
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/IdSearch">
                              <span style={{ marginLeft: "20px" }}></span>{" "}
                              <FontAwesomeIcon
                                icon={faIdCardClip}
                                style={{ marginRight: "5px" }}
                              />
                              ID Search
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/GlobalSearch">
                              <span style={{ marginLeft: "20px" }}></span>
                              <FontAwesomeIcon
                                icon={faGlobe}
                                style={{ marginRight: "5px" }}
                              />
                              Global Search
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/SaveSearch">
                              <span style={{ marginLeft: "20px" }}></span>
                              <FontAwesomeIcon
                                icon={faFloppyDisk}
                                style={{ marginRight: "5px" }}
                              />
                              Save Search
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/FilterSearch">
                              <span style={{ marginLeft: "20px" }}></span>
                              <FontAwesomeIcon
                                icon={faFloppyDisk}
                                style={{ marginRight: "5px" }}
                              />
                              Filter Search
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/Diff_profile">
                              <span style={{ marginLeft: "20px" }}></span>
                              <FontAwesomeIcon
                                icon={faSearch}
                                style={{ marginRight: "5px" }}
                              />
                              Explore Search
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown-dashnav">
                        <Link
                          to="#"
                          style={{
                            color: "white",
                            fontSize: "18px",
                            fontWeight: "700px",
                          }}
                        >
                          My activities
                        </Link>
                        <ul>
                          <li>
                            <Link to="/dashboard/ConnectedMembers">
                              <span style={{ marginLeft: "15px" }}></span>{" "}
                              <FontAwesomeIcon
                                icon={faNetworkWired}
                                style={{ marginRight: "5px" }}
                              />
                              Connected Members
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/ViewedProfile">
                              <span style={{ marginLeft: "15px" }}></span>
                              <FontAwesomeIcon
                                icon={faStreetView}
                                style={{ marginRight: "5px" }}
                              />
                              Viewed profile
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/ShortlistedByMe">
                              <span style={{ marginLeft: "15px" }}></span>
                              <FontAwesomeIcon
                                icon={faThumbsUp}
                                style={{ marginRight: "5px" }}
                              />
                              Liked By Me
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/ViewedContactList">
                              <span style={{ marginLeft: "15px" }}></span>
                              <FontAwesomeIcon
                                icon={faAddressBook}
                                style={{ marginRight: "5px" }}
                              />
                              Viewed Contact List
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/ProfileIHaveIgnore">
                              <span style={{ marginLeft: "15px" }}></span>{" "}
                              <FontAwesomeIcon
                                icon={faEject}
                                style={{ marginRight: "5px" }}
                              />
                              Profile I Have Ignore
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/RequestPending">
                              <span style={{ marginLeft: "15px" }}></span>
                              <FontAwesomeIcon
                                icon={faSpinner}
                                style={{ marginRight: "5px" }}
                              />
                              Request Pending
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/ProfileIHaveBlock">
                              <span style={{ marginLeft: "15px" }}></span>
                              <FontAwesomeIcon
                                icon={faStop}
                                style={{ marginRight: "5px" }}
                              />{" "}
                              Profile I Have Block
                            </Link>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown-dashnav">
                        <Link
                          to="#"
                          style={{
                            color: "white",
                            fontSize: "18px",
                            fontWeight: "700px",
                          }}
                        >
                          other activities
                        </Link>

                        <ul>
                          <li>
                            <Link to="/dashboard/WhoConnectedMe">
                              <span style={{ marginLeft: "15px" }}></span>
                              <FontAwesomeIcon
                                icon={faStop}
                                style={{ marginRight: "10px" }}
                              />
                              Who Connected Me
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/WhoViewedMyProfile">
                              <span style={{ marginLeft: "15px" }}></span>
                              <FontAwesomeIcon
                                icon={faUsersViewfinder}
                                style={{ marginRight: "10px" }}
                              />
                              Who Viewed My profile
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/WhoShortlistedMe">
                              <span style={{ marginLeft: "15px" }}></span>
                              <FontAwesomeIcon
                                icon={faPeopleLine}
                                style={{ marginRight: "10px" }}
                              />
                              Who Liked By Me
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/WhoViewedMyContactNumber">
                              {" "}
                              <span style={{ marginLeft: "15px" }}></span>
                              <FontAwesomeIcon
                                icon={faAddressCard}
                                style={{ marginRight: "10px" }}
                              />
                              Who Viewed My Contact Number
                            </Link>
                          </li>
                          <li>
                            <Link to="/dashboard/IntersetedRecived">
                              <span style={{ marginLeft: "15px" }}></span>
                              <FontAwesomeIcon
                                icon={faMap}
                                style={{ marginRight: "10px" }}
                              />
                              Interset Recived
                            </Link>
                          </li>
                        </ul>
                        <div className="dropdown-dashnav">
                          <span className="fa fa-angle-down" />
                        </div>
                      </li>
                      <li className="dropdown-dashnav">
                        <Link
                          to="#"
                          style={{
                            color: "white",
                            fontSize: "18px",
                            fontWeight: "700px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faBell}
                            size="lg"
                            style={{
                              "--fa-primary-color": "#f0f4f4",
                              "--fa-secondary-color": "#f0f4f4",
                            }}
                          />
                        </Link>
                        <ul>
                          <li>
                            <div style={{ display: "flex", padding: "10px" }}>
                              <img
                                src="https://images.pexels.com/photos/1542085/pexels-photo-1542085.jpeg?auto=compress&cs=tinysrgb&w=600"
                                alt="profile"
                                style={{
                                  width: "50px",
                                  height: "60px",
                                  borderRadius: "50%",
                                  marginRight: "20px",
                                }}
                              />
                              <div>
                                <h5
                                  style={{ color: "black", fontWeight: "600" }}
                                >
                                  Perry
                                </h5>
                                <p style={{ color: "grey", fontWeight: "550" }}>
                                  viewed your profile
                                </p>
                                <Link
                                  to="/vi"
                                  style={{ fontWeight: "600", color: "orange" }}
                                >
                                  view profile
                                </Link>
                              </div>
                            </div>
                          </li>
                          <hr style={{ color: "grey" }} />
                          <li>
                            <div style={{ display: "flex", padding: "10px" }}>
                              <img
                                src="https://images.pexels.com/photos/3779760/pexels-photo-3779760.jpeg?auto=compress&cs=tinysrgb&w=600"
                                alt="profile"
                                style={{
                                  width: "50px",
                                  height: "60px",
                                  borderRadius: "50%",
                                  marginRight: "20px",
                                }}
                              />
                              <div>
                                <h5
                                  style={{ color: "black", fontWeight: "600" }}
                                >
                                  Alexa
                                </h5>
                                <p style={{ color: "grey", fontWeight: "550" }}>
                                  viewed your profile
                                </p>
                                <Link
                                  to="/vi"
                                  style={{ fontWeight: "600", color: "orange" }}
                                >
                                  view profile
                                </Link>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li className="dropdown-dashnav">
                        <Link
                          to="#"
                          style={{
                            color: "white",
                            fontSize: "18px",
                            fontWeight: "700px",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faMessage}
                            style={{ color: "#fcfcfd" }}
                          />
                        </Link>
                        <ul>
                          <li>
                            <Link to="/dashboard/ChatBox">
                              <FontAwesomeIcon icon={faComments} />
                              <span style={{ marginLeft: "15px" }}></span> Chat
                              Box
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </nav>
                {/* -----profile dropdown-------------- */}
                &nbsp; &nbsp; &nbsp; &nbsp;
                <button
                  onClick={dropdowntoogle}
                  className="profile-dropdown-toggle"
                >
                  Krishnamurthy
                </button>
                {ispopup && (
                  <ul className="profile-dropdown-menu">
                    <li
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "20px",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                    >
                      <img
                        src="https://i.pinimg.com/564x/a1/15/58/a1155837ef3e36440eb687c3399e2abc.jpg"
                        style={{
                          width: "25px",
                          height: "25px",
                          borderRadius: "50%",
                        }}
                      />
                      <Link>Krishnamurthy@gmail.com</Link>
                    </li>
                    <div
                      style={{
                        width: "90%",
                        height: "0.5px",
                        background: "gray",
                        margin: "auto",
                      }}
                    ></div>
                    <li className="dropdown-dashboard-list">
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ color: "black" }}
                      />
                      <Link to="/dashboard/MyProfilefile">View profile</Link>
                    </li>
                    <li className="dropdown-dashboard-list">
                      <FontAwesomeIcon
                        icon={faUserCheck}
                        style={{ color: "black" }}
                      />
                      <Link to="/dashboard/Verifyprofile">Verify Profile</Link>
                    </li>
                    <li className="dropdown-dashboard-list">
                      <i class="ri-gallery-fill" style={{ color: "black" }}></i>
                      <Link to="/dashboard/Gallery">Gallery</Link>
                    </li>
                    <li className="dropdown-dashboard-list">
                      <i
                        class="ri-delete-bin-fill"
                        style={{ color: "black" }}
                      ></i>
                      <Link to="/dashboard/Delete">Delete profile</Link>
                    </li>
                    <div
                      style={{
                        width: "90%",
                        height: "0.5px",
                        background: "gray",
                        margin: "auto",
                      }}
                    ></div>
                    <li className="dropdown-dashboard-list">
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        style={{ color: "black" }}
                      />
                      <Link to="/dashboard/SuccessDetail">Success story</Link>
                    </li>
                    <li className="dropdown-dashboard-list">
                      <FontAwesomeIcon
                        icon={faUserPlus}
                        style={{ color: "black" }}
                      />
                      <Link>Invite Collegues</Link>
                    </li>
                    <li className="dropdown-dashboard-list">
                      <FontAwesomeIcon
                        icon={faGear}
                        style={{ color: "black" }}
                      />
                      <Link to="/dashboard/Usersetting">Settings</Link>
                    </li>
                    <li className="dropdown-dashboard-list">
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        style={{ color: "black" }}
                      />
                      <Link to="/dashboard/HelpDesk">Help</Link>
                    </li>

                    <div
                      style={{
                        width: "90%",
                        height: "0.5px",
                        background: "gray",
                        margin: "auto",
                      }}
                    ></div>

                    <li className="dropdown-dashboard-list">
                      <FontAwesomeIcon
                        icon={faSignOut}
                        style={{ color: "black" }}
                      />
                      <Link to="/Login">Logout</Link>
                    </li>
                  </ul>
                )}
                {/* Hamburger Menu Button */}
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleDrawerOpen}
                  className="menu-icon-button-dashnav"
                >
                  <MenuIcon
                    style={{
                      fontSize: "3rem",
                      height: "50px",
                      width: "50px",
                      backgroundColor: "white",
                    }}
                  />
                </IconButton>
                {/* Responsive Drawer */}
                <Drawer
                  anchor="left"
                  open={isDrawerOpen}
                  onClose={handleDrawerClose}
                  style={{ background: "transparent" }}
                >
                  <div style={{ backgroundColor: "black" }}>
                    <List>
                      <ListItem button onClick={toggleDropdown}>
                        <ListItemIcon>
                          {/* <AccountCircleIcon style={{ color: 'yellow' }} /> */}
                          <img
                            src="https://i.pinimg.com/564x/a1/15/58/a1155837ef3e36440eb687c3399e2abc.jpg"
                            style={{
                              width: "35px",
                              height: "35px",
                              borderRadius: "50%",
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            variant="h6"
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                            }}
                          >
                            <Link
                              to="#"
                              style={{
                                textDecoration: "none",
                                color: "whitesmoke",
                              }}
                            >
                              Jhon doe
                            </Link>
                          </Typography>
                        </ListItemText>
                        <FontAwesomeIcon
                          icon={faAngleLeft}
                          rotation={270}
                          size="2xl"
                          style={{ color: "#f1f2f3" }}
                        />
                      </ListItem>
                      {isOpen && (
                        <>
                          <li
                            className="dropdown-dashboard-list"
                            style={{
                              gap: "30px",
                              marginTop: "5px",
                              fontWeight: "600",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faUser}
                              style={{ fontSize: "20px" }}
                            />
                            <Link
                              to="/dashboard/MyProfilefile"
                              style={{ color: "white", fontSize: "20px" }}
                            >
                              View profile
                            </Link>
                          </li>
                          <hr style={{ color: "white" }} />
                          <li
                            className="dropdown-dashboard-list"
                            style={{
                              gap: "30px",
                              marginTop: "5px",
                              fontWeight: "600",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faUserCheck}
                              style={{ fontSize: "20px" }}
                            />
                            <Link
                              to="/dashboard/Verifyprofile"
                              style={{ color: "white", fontSize: "20px" }}
                            >
                              Verify Profile
                            </Link>
                          </li>
                          <hr style={{ color: "white" }} />
                          <li
                            className="dropdown-dashboard-list"
                            style={{
                              gap: "30px",
                              marginTop: "5px",
                              fontWeight: "600",
                            }}
                          >
                            <i
                              class="ri-gallery-fill"
                              style={{ fontSize: "20px" }}
                            ></i>
                            <Link
                              to="/dashboard/Gallery"
                              style={{ color: "white", fontSize: "20px" }}
                            >
                              Gallery
                            </Link>
                          </li>
                          <hr style={{ color: "white" }} />
                          <li
                            className="dropdown-dashboard-list"
                            style={{
                              gap: "30px",
                              marginTop: "5px",
                              fontWeight: "600",
                            }}
                          >
                            <i
                              class="ri-delete-bin-fill"
                              style={{ fontSize: "20px" }}
                            ></i>
                            <Link
                              to="/dashboard/Delete"
                              style={{ color: "white", fontSize: "20px" }}
                            >
                              Delete profile
                            </Link>
                          </li>
                          <hr style={{ color: "white" }} />
                          <li
                            className="dropdown-dashboard-list"
                            style={{
                              gap: "30px",
                              marginTop: "5px",
                              fontWeight: "600",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faCheck}
                              style={{ fontSize: "20px" }}
                            />
                            <Link
                              to="/dashboard/SuccessDetail"
                              style={{ color: "white", fontSize: "20px" }}
                            >
                              Success story
                            </Link>
                          </li>
                          <hr style={{ color: "white" }} />

                          <li
                            className="dropdown-dashboard-list"
                            style={{
                              gap: "30px",
                              marginTop: "5px",
                              fontWeight: "600",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faGear}
                              style={{ fontSize: "20px" }}
                            />
                            <Link
                              to="/dashboard/Usersetting"
                              style={{ color: "white", fontSize: "20px" }}
                            >
                              Settings
                            </Link>
                          </li>
                          <hr style={{ color: "white" }} />
                          <li
                            className="dropdown-dashboard-list"
                            style={{
                              gap: "30px",
                              marginTop: "5px",
                              fontWeight: "600",
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faQuestionCircle}
                              style={{ fontSize: "20px" }}
                            />
                            <Link
                              to="/dashboard/HelpDesk"
                              style={{ color: "white", fontSize: "20px" }}
                            >
                              Help
                            </Link>
                          </li>
                        </>
                      )}
                      <hr style={{ color: "white" }} />
                      <ListItem button onClick={handleProfileClick}>
                        <ListItemIcon>
                          <AccountCircleIcon style={{ color: "yellow" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            variant="h6"
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                            }}
                          >
                            <Link
                              to="#"
                              style={{
                                textDecoration: "none",
                                color: "yellow",
                              }}
                            >
                              Profiles
                            </Link>
                          </Typography>
                        </ListItemText>
                        <FontAwesomeIcon
                          icon={faAngleLeft}
                          rotation={270}
                          size="2xl"
                          style={{ color: "#f1f2f3" }}
                        />
                      </ListItem>
                      {isProfileSubMenuOpen && (
                        <ListItem button onClick={handleDrawerClose}>
                          {/* Submenu items */}
                          <ul>
                            <li
                              style={{ marginBottom: "10px" }}
                              className="dropdown-nav"
                            >
                              <Link
                                to="/dashboard/MyProfilefile"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-person-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                                </svg>{" "}
                                <span style={{ marginLeft: "15px" }}></span>
                                My profile
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li style={{ marginBottom: "10px" }}>
                              <Link
                                to="/dashboard/Basicdetails"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-info-circle-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                                </svg>
                                <span style={{ marginLeft: "15px" }}></span>
                                Basic details
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li style={{ marginBottom: "10px" }}>
                              <Link
                                to="/dashboard/Contactus"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <i
                                  class="ri-contacts-book-3-fill"
                                  style={{ color: "white" }}
                                ></i>
                                <span style={{ marginLeft: "15px" }}></span>
                                Contact Details
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li style={{ marginBottom: "10px" }}>
                              <Link
                                to="/dashboard/Horoscope"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-yin-yang"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M9.167 4.5a1.167 1.167 0 1 1-2.334 0 1.167 1.167 0 0 1 2.334 0Z" />
                                  <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM1 8a7 7 0 0 1 7-7 3.5 3.5 0 1 1 0 7 3.5 3.5 0 1 0 0 7 7 7 0 0 1-7-7Zm7 4.667a1.167 1.167 0 1 1 0-2.334 1.167 1.167 0 0 1 0 2.334Z" />
                                </svg>
                                <span style={{ marginLeft: "15px" }}></span>
                                Horoscope
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li style={{ marginBottom: "10px" }}>
                              <Link
                                to="/dashboard/Education"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-mortarboard-fill"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M8.211 2.047a.5.5 0 0 0-.422 0l-7.5 3.5a.5.5 0 0 0 .025.917l7.5 3a.5.5 0 0 0 .372 0L14 7.14V13a1 1 0 0 0-1 1v2h3v-2a1 1 0 0 0-1-1V6.739l.686-.275a.5.5 0 0 0 .025-.917l-7.5-3.5Z" />
                                  <path d="M4.176 9.032a.5.5 0 0 0-.656.327l-.5 1.7a.5.5 0 0 0 .294.605l4.5 1.8a.5.5 0 0 0 .372 0l4.5-1.8a.5.5 0 0 0 .294-.605l-.5-1.7a.5.5 0 0 0-.656-.327L8 10.466 4.176 9.032Z" />
                                </svg>
                                <span style={{ marginLeft: "15px" }}></span>
                                Education
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li style={{ marginBottom: "10px" }}>
                              <Link
                                to="/dashboard/Family"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  fill="currentColor"
                                  class="bi bi-person-hearts"
                                  viewBox="0 0 16 16"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M11.5 1.246c.832-.855 2.913.642 0 2.566-2.913-1.924-.832-3.421 0-2.566ZM9 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-9 8c0 1 1 1 1 1h10s1 0 1-1-1-4-6-4-6 3-6 4Zm13.5-8.09c1.387-1.425 4.855 1.07 0 4.277-4.854-3.207-1.387-5.702 0-4.276ZM15 2.165c.555-.57 1.942.428 0 1.711-1.942-1.283-.555-2.281 0-1.71Z"
                                  />
                                </svg>
                                <span style={{ marginLeft: "15px" }}></span>
                                Family
                              </Link>
                              <hr style={{ color: "white" }} />
                              <li style={{ marginBottom: "10px" }}>
                                <Link
                                  to="/dashboard/Prefrence"
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: 500,
                                  }}
                                >
                                  {" "}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-gender-ambiguous"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M11.5 1a.5.5 0 0 1 0-1h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-1 0V1.707l-3.45 3.45A4 4 0 0 1 8.5 10.97V13H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V14H6a.5.5 0 0 1 0-1h1.5v-2.03a4 4 0 1 1 3.471-6.648L14.293 1H11.5zm-.997 4.346a3 3 0 1 0-5.006 3.309 3 3 0 0 0 5.006-3.31z"
                                    />
                                  </svg>
                                  <span style={{ marginLeft: "15px" }}></span>
                                  Partner Preference
                                </Link>
                              </li>
                              <hr style={{ color: "white" }} />
                              <li style={{ marginBottom: "10px" }}>
                                <Link
                                  to="/dashboard/interestslink"
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: 500,
                                  }}
                                >
                                  <i class="ri-pinterest-fill"></i>{" "}
                                  <span style={{ marginLeft: "15px" }}></span>
                                  Interests
                                </Link>
                              </li>
                              <hr style={{ color: "white" }} />
                              <li style={{ marginBottom: "10px" }}>
                                <Link
                                  to="/dashboard/Compatability"
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: 500,
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-journal-check"
                                    viewBox="0 0 16 16"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M10.854 6.146a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7.5 8.793l2.646-2.647a.5.5 0 0 1 .708 0z"
                                    />
                                    <path d="M3 0h10a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-1h1v1a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v1H1V2a2 2 0 0 1 2-2z" />
                                    <path d="M1 5v-.5a.5.5 0 0 1 1 0V5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0V8h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1zm0 3v-.5a.5.5 0 0 1 1 0v.5h.5a.5.5 0 0 1 0 1h-2a.5.5 0 0 1 0-1H1z" />
                                  </svg>{" "}
                                  <span style={{ marginLeft: "15px" }}></span>
                                  My Compatibility
                                </Link>
                              </li>
                              <hr style={{ color: "white" }} />
                              <li style={{ marginBottom: "10px" }}>
                                <Link
                                  to="/dashboard/Mysetting"
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: 500,
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-gear-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                                  </svg>{" "}
                                  <span style={{ marginLeft: "15px" }}></span>
                                  My Setting
                                </Link>
                              </li>
                              <hr style={{ color: "white" }} />
                              <li style={{ marginBottom: "10px" }}>
                                <Link
                                  to="/dashboard/HabitsForm"
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: 500,
                                  }}
                                >
                                  <i class="ri-drinks-2-fill"></i>{" "}
                                  <span style={{ marginLeft: "15px" }}></span>
                                  Habits Form
                                </Link>
                              </li>
                              <hr style={{ color: "white" }} />
                              <li style={{ marginBottom: "10px" }}>
                                <Link
                                  to="/dashboard/HobbyDetails"
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: 500,
                                  }}
                                >
                                  <i class="ri-football-fill"></i>{" "}
                                  <span style={{ marginLeft: "15px" }}></span>
                                  Hobbies Details
                                </Link>
                              </li>
                              <hr style={{ color: "white" }} />
                              <li style={{ marginBottom: "10px" }}>
                                <Link
                                  to="/dashboard/IdProof"
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: 500,
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-person-vcard-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm9 1.5a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4a.5.5 0 0 0-.5.5ZM9 8a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 0-1h-4A.5.5 0 0 0 9 8Zm1 2.5a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 0-1h-3a.5.5 0 0 0-.5.5Zm-1 2C9 10.567 7.21 9 5 9c-2.086 0-3.8 1.398-3.984 3.181A1 1 0 0 0 2 13h6.96c.026-.163.04-.33.04-.5ZM7 6a2 2 0 1 0-4 0 2 2 0 0 0 4 0Z" />
                                  </svg>{" "}
                                  <span style={{ marginLeft: "15px" }}></span>
                                  Upload ID Proof
                                </Link>
                              </li>
                              <hr style={{ color: "white" }} />
                              <li style={{ marginBottom: "10px" }}>
                                <Link
                                  to="/dashboard/Uploaddocument"
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: 500,
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-file-earmark-arrow-up-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707L6.354 9.854z" />
                                  </svg>{" "}
                                  <span style={{ marginLeft: "15px" }}></span>
                                  Upload Document
                                </Link>
                              </li>
                              <hr style={{ color: "white" }} />
                              <li style={{ marginBottom: "10px" }}>
                                <Link
                                  to="/dashboard/Newchangepassword"
                                  style={{
                                    textDecoration: "none",
                                    color: "white",
                                    fontSize: "20px",
                                    fontWeight: 500,
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-file-lock-fill"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M7 6a1 1 0 0 1 2 0v1H7V6zM6 8.3c0-.042.02-.107.105-.175A.637.637 0 0 1 6.5 8h3a.64.64 0 0 1 .395.125c.085.068.105.133.105.175v2.4c0 .042-.02.107-.105.175A.637.637 0 0 1 9.5 11h-3a.637.637 0 0 1-.395-.125C6.02 10.807 6 10.742 6 10.7V8.3z" />
                                    <path d="M12 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm-2 6v1.076c.54.166 1 .597 1 1.224v2.4c0 .816-.781 1.3-1.5 1.3h-3c-.719 0-1.5-.484-1.5-1.3V8.3c0-.627.46-1.058 1-1.224V6a2 2 0 1 1 4 0z" />
                                  </svg>{" "}
                                  <span style={{ marginLeft: "15px" }}></span>
                                  Change Password
                                </Link>
                              </li>
                            </li>
                          </ul>
                        </ListItem>
                      )}
                      <hr style={{ color: "white" }} />
                      <ListItem button onClick={handleMatchClick}>
                        <ListItemIcon>
                          <FavoriteIcon style={{ color: "yellow" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            variant="h6"
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                            }}
                          >
                            <Link
                              to="#"
                              style={{
                                textDecoration: "none",
                                color: "yellow",
                              }}
                            >
                              Matches
                            </Link>
                          </Typography>
                        </ListItemText>
                        <FontAwesomeIcon
                          icon={faAngleLeft}
                          rotation={270}
                          size="2xl"
                          style={{ color: "#f1f2f3" }}
                        />
                      </ListItem>
                      {isMatchSubMenuOpen && (
                        <ListItem button onClick={handleDrawerClose}>
                          {/* Submenu items */}
                          <ul>
                            <li
                              style={{ marginBottom: "10px" }}
                              className="dropdown-nav"
                            >
                              <Link
                                to="/dashboard/LatestMatches"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faWandMagic}
                                  style={{ fontSize: "20px" }}
                                />
                                <span style={{ marginLeft: "15px" }}></span>
                                Latest Matches
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li style={{ marginBottom: "10px" }}>
                              <Link
                                to="/dashboard/MutalMatches"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faHeart} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Mutual Matches
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li style={{ marginBottom: "10px" }}>
                              <Link
                                to="/dashboard/CompatibulityMatches"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faPeoplePulling} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Compatibility Matches
                              </Link>
                            </li>
                          </ul>
                        </ListItem>
                      )}
                      <hr style={{ color: "white" }} />
                      <ListItem button onClick={handleSearchClick}>
                        <ListItemIcon>
                          <SearchIcon style={{ color: "yellow" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            variant="h6"
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                            }}
                          >
                            <Link
                              to="#"
                              style={{
                                textDecoration: "none",
                                color: "yellow",
                              }}
                            >
                              Search
                            </Link>
                          </Typography>
                        </ListItemText>
                        <FontAwesomeIcon
                          icon={faAngleLeft}
                          rotation={270}
                          size="2xl"
                          style={{ color: "#f1f2f3" }}
                        />
                      </ListItem>
                      {isSearchSubMenuOpen && (
                        <ListItem button onClick={handleDrawerClose}>
                          {/* Submenu items */}
                          <ul>
                            <li>
                              <Link
                                to="/dashboard/SearchProfile"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faSearch} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Regular Search
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li>
                              <Link
                                to="/dashboard/AdvanceSearch"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faSearch} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Advance Search
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li>
                              <Link
                                to="/dashboard/IdSearch"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faIdCardClip} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Id Search
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li>
                              <Link
                                to="/dashboard/GlobalSearch"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faGlobe} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Global search
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li>
                              <Link
                                to="/dashboard/SaveSearch"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faFloppyDisk} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Save Search
                              </Link>
                            </li>
                          </ul>
                        </ListItem>
                      )}
                      <hr style={{ color: "white" }} />
                      <ListItem button onClick={handleACClick}>
                        <ListItemIcon>
                          <DirectionsRunIcon style={{ color: "yellow" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            variant="h6"
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                            }}
                          >
                            <Link
                              to="#"
                              style={{
                                textDecoration: "none",
                                color: "yellow",
                              }}
                            >
                              My Activities
                            </Link>
                          </Typography>
                        </ListItemText>
                        <FontAwesomeIcon
                          icon={faAngleLeft}
                          rotation={270}
                          size="2xl"
                          style={{ color: "#f1f2f3" }}
                        />
                      </ListItem>
                      {isACSubMenuOpen && (
                        <ListItem button onClick={handleDrawerClose}>
                          {/* Submenu items */}
                          <ul>
                            <li>
                              <Link
                                to="/dashboard/ConnectedMembers"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faNetworkWired} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Connected Members
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li>
                              <Link
                                to="/dashboard/ViewedProfile"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faStreetView} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Viewed Profile
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li>
                              <Link
                                to="/dashboard/ShortlistedByMe"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faThumbsUp} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Liked by me
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li>
                              <Link
                                to="/dashboard/ViewedContactList"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faAddressBook} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Viewed Contact List
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li>
                              <Link
                                to="/dashboard/ProfileIHaveIgnore"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faEject} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Profile I Have Ignore
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li>
                              <Link
                                to="/dashboard/RequestPending"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faSpinner} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Request Pending
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li>
                              <Link
                                to="/dashboard/ProfileIHaveBlock"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faStop} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Profile I have Block
                              </Link>
                            </li>
                          </ul>
                        </ListItem>
                      )}
                      <hr style={{ color: "white" }} />
                      <ListItem button onClick={handleOAClick}>
                        <ListItemIcon>
                          <MoreHorizIcon style={{ color: "yellow" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            variant="h6"
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                            }}
                          >
                            <Link
                              to="#"
                              style={{
                                textDecoration: "none",
                                color: "yellow",
                              }}
                            >
                              Other Activities
                            </Link>
                          </Typography>
                        </ListItemText>
                        <FontAwesomeIcon
                          icon={faAngleLeft}
                          rotation={270}
                          size="2xl"
                          style={{ color: "#f1f2f3" }}
                        />
                      </ListItem>
                      {isOASubMenuOpen && (
                        <ListItem button onClick={handleDrawerClose}>
                          {/* Submenu items */}
                          <ul>
                            <li style={{ marginBottom: "10px" }}>
                              <Link
                                to="/dashboard/WhoConnectedMe"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faUserCheck}
                                  style={{ fontSize: "20px" }}
                                />
                                <span style={{ marginLeft: "15px" }}></span>
                                Who Connected Me
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li style={{ marginBottom: "10px" }}>
                              <Link
                                to="/dashboard/WhoViewedMyProfile"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faUsersViewfinder} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Who viewed My Profile
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li style={{ marginBottom: "10px" }}>
                              <Link
                                to="/dashboard/WhoShortlistedMe"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faPeopleLine} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Who Liked Me
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li style={{ marginBottom: "10px" }}>
                              <Link
                                to="/dashboard/WhoViewedMyContactNumber"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faAddressCard} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Who Viewed My Contact Number
                              </Link>
                            </li>
                            <hr style={{ color: "white" }} />
                            <li style={{ marginBottom: "10px" }}>
                              <Link
                                to="/dashboard/IntersetedRecived"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faMap} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Interest Received
                              </Link>
                            </li>
                          </ul>
                        </ListItem>
                      )}
                      <hr style={{ color: "white" }} />
                      <ListItem button onClick={handleMessageClick}>
                        <ListItemIcon>
                          <MessageIcon style={{ color: "yellow" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            variant="h6"
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                            }}
                          >
                            <Link
                              to="#"
                              style={{
                                textDecoration: "none",
                                color: "yellow",
                              }}
                            >
                              Messages
                            </Link>
                          </Typography>
                        </ListItemText>
                        <FontAwesomeIcon
                          icon={faAngleLeft}
                          rotation={270}
                          size="2xl"
                          style={{ color: "#f1f2f3" }}
                        />
                      </ListItem>
                      {isMessageSubMenuOpen && (
                        <ListItem button onClick={handleDrawerClose}>
                          {/* Submenu items */}
                          <ul>
                            <li>
                              <Link
                                to="/dashboard/ChatBox"
                                style={{
                                  textDecoration: "none",
                                  color: "white",
                                  fontSize: "20px",
                                  fontWeight: 500,
                                }}
                              >
                                <FontAwesomeIcon icon={faComments} />
                                <span style={{ marginLeft: "15px" }}></span>
                                Chat Box
                              </Link>
                            </li>
                          </ul>
                        </ListItem>
                      )}
                      <hr style={{ color: "white" }} />
                      <ListItem button onClick={handleDrawerClose}>
                        <ListItemIcon>
                          <ExitToAppIcon style={{ color: "yellow" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography
                            variant="h6"
                            style={{
                              color: "white",
                              fontWeight: "bold",
                              fontSize: "1.2rem",
                            }}
                          >
                            <Link
                              to="/Login"
                              style={{
                                textDecoration: "none",
                                color: "yellow",
                              }}
                            >
                              Logout
                            </Link>
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    </List>
                  </div>
                </Drawer>
              </div>
            </div>
          </div>
        </header>
        <div style={{ marginTop: "115px" }}>{/* <UserProfiles /> */}</div>
      </div>
    </div>
  );
};
export default NewDashboard;
