import React, { useState } from "react";
import "./CommonStyleSheetForSearchInputComponentss.css";

const IdSearch = () => {
  let [change, setChange] = useState('');

  function handleChange(e) {
    setChange(e.target.value)
  }

  function handleSubmit(e) {
    if (change.length < 2) alert("Entert an ID")
  }

  return (
    <div className="common-container-search-components">
        <h1 className="common-search-title-tag">ID Search</h1>
        <div className="common-search-select-wraper">

          <div className="select-tag-parent-wraper id-search-select-tag-parent-wraper">
            <div className="select-tag-wraper">
              <label className="search-tag-lable"> Select Gender</label>
              <input
                style={{ margin: "auto" }}
                className="search-section-select-tag"
                placeholder="Enter ID"
                name="id-search"
                onChange={e => handleChange(e)}
                value={change ? change : ''}
              ></input>
              
            </div>
          </div>
          <div className="global-search-button-wraper">
            <button onClick={e => handleSubmit(e)} className="search-section-button">Search</button>
          </div>
        </div>
    </div>
  );
};

export default IdSearch;
