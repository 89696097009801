import React from "react";
import "../Images/logo.png";
import Basicdetails from "../Basicdetails/Basicdetails";
import "./Review";
import "../chatbox/ChatBox";
import NewDashboard from "../NewDashboard/NewDashboard";
// import Footer from "../Footer/Footer";
// import Userdata from "./UserProfilee/Userdata";
// import Complete_profile from "./Complete_profile";
import Contactus from "../Contactus/Contactus";
import ProfileCards from "../Horoscopepage/ProfileCards";
import { Routes, Route } from "react-router-dom";
import Family from "../FamilyDetails/Family";
import Education from "../EducationDetails/Education";
import Myprofilefile from "../Myprofile/Myprofilefile";
import Horoscope from "../Horoscopepage/Horoscope.jsx";
import Profiledetails from "../Basicdetails/Profiledetails.js";
// import Uploaddocument from "../UploadDocument/Uploaddocument";
import Notification from "../Notification/Notification";
import GlobalSearch from "../Search/GlobalSearch";
import FilterSearch from "../Search/FilterSearch";
import SaveSearch from "../Search/SaveSearch";
import Interestslink from "./UserProfilee/Interestslink";
import Diff_profile from "../Search/Diff_profile";
import Gallery from "../Gallery";
import Couplegallery from "../../Couplegallery";
import My_plan from "../../My_plan";
import Success from "../Successstory/Success";
import ReviewCards from "./ReviewCards";
import Singleview from "../Horoscopepage/Singleview";
import ChatBox from "../chatbox/ChatBox";
import Review from "./Review";
import FeedbackDetails from "../Feedbacks/FeedbackDetails";
import Feedbackviewmore from "../Feedbacks/Feedbackviewmore";
import ConnectedMembers from "../Connected  Members/ConnectedMembers";
import ViewedProfile from "../ViewedProfile/ViewedProfile";
import ShortlistedByMe from "../ShortListedByMe/ShortlistedByMe";
import ViewedContactList from "../ViewedContactList/ViewedContactList";
import ProfileIHaveIgnore from "../Profile I Have Ignore/ProfileIHaveIgnore";
import RequestPending from "../RequestPending/RequestPending";
import ProfileIHaveBlock from "../ProfileIHaveBlock/ProfileIHaveBlock";
import WhoShortlistedMe from "../OtherActivites/WhoShortlistedMe";
import WhoViewedMyContactNumber from "../OtherActivites/WhoViewedMyContactNumber";
import WhoConnectedMe from "../OtherActivites/WhoConnectedMe";
import WhoViewedMyProfile from "../OtherActivites/WhoViewedMyProfile";
import InterstedRecived from "../OtherActivites/InterstedRecived";
import LatestMatches from "../Matches/LatestMatches";
import MutalMatches from "../Matches/MutalMatches";
import CompatibulityMatches from "../Matches/CompatibulityMatches";
import Profile from "../Your Profile/Profile";
// import IdProof from "../Upload ID Proof/IdProof";
import Prefrence from "../Partner Prefrence/Prefrence";
import Compatability from "../Compatability/Compatability";
import Newintrest from "../Newintrest/Newintrest";
import AdvanceSearch from "../Search/AdvanceSearch";
import SearchProfile from "../Search/SearchProfile";
import IdSearch from "../Search/IdSearch";
import UserProfiles from "./UserProfilee/UserProfiles";
import Newchangepassword from "./UserProfilee/Newchangepassword";
import HabitsForm from "../Habits/HabitsForm";
// import HobbyDetails from "./HobbyDetails";
import Edit from "./Edit";
import SuccessDetail from "../Successstory/SuccessDetail";
import NewViewe from "../NewViewed/NewViewe";
import Usersetting from "./Usersetting";
import NotFound from "../NotFound";
import Overview from "./Overview";
import Addphoto from "./UserProfilee/Addphoto";
import Addimages from "./UserProfilee/Addimages";
import Delete from "../Deletefile/Delete";
import ChatApp from "../Chatapp/chat.js";
import Addcouplesimage from "./Addcouplesimage.js";
import Verifyprofile from "../Verifyprofile/Verifyprofile.js";
import Compatabilitydetails from "../Compatability/Compatabilitydetails.js";
import HelpDesk from "../../HelpDesk.jsx";
import BasicSearch from "../Search/BasicSearch.jsx";
import NewIdProof from "../Myprofile/Newidproof";
import UploadIdAndDocument from "../UploadIdAndDocument/UploadIdAndDocument.jsx";

const UserdashBoard = () => {
  return (
    <>
      <div>
        <NewDashboard />
        <Routes>
          <Route path="/" element={<Overview />} />
          <Route path="overview" element={<Overview />} />
          <Route path="/Family" exact element={<Family />} />
          <Route path="/Education" exact element={<Education />} />
          <Route path="/Myprofilefile" exact element={<Myprofilefile />} />
          <Route path="/Horoscope" exact element={<Horoscope />} />
          <Route path="/basicAndProfiledetails" element={<Basicdetails />} />
          <Route path="/Profiledetails" exact element={<Profiledetails />} />
          {/* <Route path="/Uploaddocument" exact element={<Uploaddocument />} /> */}
          <Route path="/UploadIdAndDocument" exact element={<UploadIdAndDocument/>} />
          
          <Route path="/Notification" exact element={<Notification />} />
          <Route path="/GlobalSearch" exact element={<GlobalSearch />} />
          <Route path="/FilterSearch" exact element={<FilterSearch />} />
          <Route path="/SaveSearch" exact element={<SaveSearch />} />
          <Route path="/Interestslink" exact element={<Interestslink />} />
          <Route path="/ProfileCards" exact element={<ProfileCards />} />
          <Route path="/Diff_profile" exact element={<Diff_profile />} />
          <Route path="/Gallery" exact element={<Gallery />} />
          <Route path="/Couplegallery" exact element={<Couplegallery />} />
          <Route path="/My_plan" element={<My_plan />} />
          <Route path="Success" element={<Success />} />
          <Route path="/ReviewCards" element={<ReviewCards />} />
          <Route path="/Singleview/:id" element={<Singleview />} />
          <Route path="/ChatBox" exact element={<ChatBox />} />
          <Route path="/Review" exact element={<Review />} />
          <Route path="/FeedbackDetails" exact element={<FeedbackDetails />} />
          <Route
              path="/ConnectedMembers"
              exact
              element={<ConnectedMembers/>}
            />
            <Route path="/ViewedProfile" exact element={<ViewedProfile />} />
            <Route
              path="/ShortListedByMe"
              exact
              element={<ShortlistedByMe/>}
            />
            <Route
              path="/ViewedContactList"
              exact
              element={<ViewedContactList/>}
            />
            <Route
              path="/ProfileIHaveIgnore"
              exact
              element={<ProfileIHaveIgnore />}
            />
            <Route path="/RequestPending" exact element={<RequestPending/>} />
            <Route
              path="/ProfileIHaveBlock"
              exact
              element={<ProfileIHaveBlock />}
            />
            <Route
              path="/WhoShortlistedMe"
              exact
              element={<WhoShortlistedMe/>}
            />
            <Route
              path="/WhoViewedMyContactNumber"
              exact
              element={<WhoViewedMyContactNumber />}
            />
            <Route path="/WhoConnectedMe" exact element={<WhoConnectedMe/>} />
            <Route
              path="/WhoViewedMyProfile"
              exact
              element={<WhoViewedMyProfile />}
            />
            <Route
              path="/IntersetedRecived"
              exact
              element={<InterstedRecived/>}
            />
            <Route path="/LatestMatches" exact element={<LatestMatches/>} />
            <Route path="/MutalMatches" exact element={<MutalMatches />} />
            <Route
              path="/CompatibulityMatches"
              exact
              element={<CompatibulityMatches />}
            />
            <Route path="/Profile" exact element={<Profile/>} />
            {/* <Route path="/IdProof" exact element={<IdProof/>} /> */}
            <Route path="/Prefrence" exact element={<Prefrence/>} />
            
            {/* <Route path="/Sendmessages" exact element={<Sendmessages />} /> */}
            {/* <Route
            path="/Feedbackviewmore/:id"
            exact
            element={<Feedbackviewmore />}
          />
          <Route path="/Addphoto" exact element={<Addphoto />} />
          <Route path="/Addimages" exact element={<Addimages />} />
          <Route path="/Contactus" exact element={<Contactus />} />
          <Route path="/Delete" exact element={<Delete />} />
          <Route path="/Addcouplesimage" exact element={<Addcouplesimage />} />
          <Route path="/HelpDesk" exact element={<HelpDesk />} />{" "}
          <Route path="/BasicSearch" exact element={<BasicSearch />} />
          <Route
            path="/ConnectedMembers"
            exact
            element={<ConnectedMembers />}
          />
          <Route path="/ViewedProfile" exact element={<ViewedProfile />} />
          <Route path="/ShortListedByMe" exact element={<ShortlistedByMe />} />
          <Route
            path="/ViewedContactList"
            exact
            element={<ViewedContactList />}
          />
          <Route
            path="/ProfileIHaveIgnore"
            exact
            element={<ProfileIHaveIgnore />}
          />
          <Route path="/RequestPending" exact element={<RequestPending />} />
          <Route
            path="/ProfileIHaveBlock"
            exact
            element={<ProfileIHaveBlock />}
          />
          <Route
            path="/WhoShortlistedMe"
            exact
            element={<WhoShortlistedMe />}
          />
          <Route
            path="/WhoViewedMyContactNumber"
            exact
            element={<WhoViewedMyContactNumber />}
          />
          <Route path="/WhoConnectedMe" exact element={<WhoConnectedMe />} />
          <Route
            path="/WhoViewedMyProfile"
            exact
            element={<WhoViewedMyProfile />}
          />
          <Route
            path="/IntersetedRecived"
            exact
            element={<InterstedRecived />}
          />
          <Route path="/LatestMatches" exact element={<LatestMatches />} />
          <Route path="/MutalMatches" exact element={<MutalMatches />} />
          <Route
            path="/CompatibulityMatches"
            exact
            element={<CompatibulityMatches />}
          />
          <Route path="/Profile" exact element={<Profile />} />
          <Route path="/IdProof" exact element={<IdProof />} />
          <Route path="/Prefrence" exact element={<Prefrence />} />
          {/* <Route path="/Sendmessages" exact element={<Sendmessages />} /> */}
          {/* <Route
              path="/Receivedmessage"
              exact
              element={<Receivedmessage/>}
            /> */}
          {/* <Route path="/Usersetting" exact element={<Usersetting />} /> */}
          <Route path="/Compatability" exact element={<Compatability />} />
          <Route
            path="/Compatabilitydetails"
            exact
            element={<Compatabilitydetails />}
          />
          <Route path="/Newintrest" exact element={<Newintrest />} />
          <Route path="/NewViewe" exact element={<NewViewe />} />
          <Route path="/AdvanceSearch" exact element={<AdvanceSearch />} />
          <Route path="/SearchProfile" exact element={<SearchProfile />} />
          <Route path="/IdSearch" exact element={<IdSearch />} />
          <Route path="/UserProfiles" exact element={<UserProfiles />} />
          <Route
            path="/Newchangepassword"
            exact
            element={<Newchangepassword />}
          />
          <Route path="/HabitsFormandDetails" exact element={<HabitsForm />} />
          {/* <Route path="/HobbyDetails" exact element={<HobbyDetails />} /> */}
          <Route path="/Edit" exact element={<Edit />} />
          <Route path="/NewDashboard" exact element={<NewDashboard />} />
          <Route path="/SuccessDetail" exact element={<SuccessDetail />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/Verifyprofile" exact element={<Verifyprofile />} />
          <Route path="/dashboard/*" element={<UserdashBoard />} />
          <Route path="/Chatapp" element={<ChatApp />} />
          <Route path="/Newidproof" element={<NewIdProof />} />
        </Routes>
      </div>
    </>
  );
};
export default UserdashBoard;
