import React from 'react'
import "./WhoViewedMyProfile.css"

const WhoViewedMyProfile = () => {
  return (
    <div>

     <div className='page-title'>
      <p>Who Viewed My Profile</p>
     </div>

      <div>
       

          <div className="who-viewed-my-profile-container">
            <h1 className="who-viewed-my-profile-heading">Who Viewed My Profile</h1>
            <div className="who-viewed-my-profile-input-container">
              <input type="text" className="who-viewed-my-profile-input" placeholder="Name" style={{ backgroundcolor: 'white' }} />
              <input type="text" className="who-viewed-my-profile-input" placeholder="Profile ID" />
              <input type="text" className="who-viewed-my-profile-input" placeholder="Viewed Date" />
            </div>
          </div>

        
      </div>
    </div>
  )
}

export default WhoViewedMyProfile
