export const initialdata = {
  moon_sign: "",
  star: "",
  gotra: "",
  manglik: "",
  shani: "",
  hororscope_match: "",
  place_of_birth: "",
  place_of_country: "",
  hours: "",
  minutes: "",
  seconds: "",
  am_pm: "",
};
export const starSign = [
  "Ardra/Thiruvathira",
  "Ashlesha/Ayilyam",
  "Ashwini/Ashwathi", "Bharani",
  "Chitra/Chitha",
  "Dhanista/Avittam",
  "Hastha/Atham",
  "Jyesta / Kettai",
  "Krithika/Karthika",
  "Makha/Magam",
  "Moolam/Moola",
  "Mrigasira/Makayiram",
  "Poorvabadrapada/Puratathi",
  "Poorvapalguni/Puram/Pubbhe",
  "Poorvashada/Pooradam",
  "Punarvasu/Punarpusam",
  "Pushya/Poosam/Pooyam", "Revathi", "Rohini",
  "Shatataraka/Sadayam/Satabishek",
  "Shravan/Thiruvonam",
  "Swati/Chothi",
  "Uttarabadrapada/Uthratadhi",
  "Uttarapalguni/Uthram",
  "Uttarashada/Uthradam",
  "Vishaka/Vishakam",
  "Does not matter",
];
export const moonsignOptions = [
  "Mesh (Aries)",
  "Vrishabh (Taurus)",
  "Mithun (Gemini)",
  "Karka (Cancer)",
  "Sinha (Leo)",
  "Kanya (Virgo)",
  "Tula (Libra)",
  "Vrischika (Scorpio)",
  "Makar (Capricorn)",
  "Kumbha (Aquarious)",
  "Meen (Pisces)",
  "Does not matter",
];
export const mangalikOptions = [
  "Yes",
  "No",
  "Not Applicable"
];
export const shaniOptions = [
  "Yes",
  "No",
  "Not Applicable"
];
export const hoursOptions = [
  ...Array.from({ length: 12 }, (_, i) => ({ value: i + 1, label: i + 1 })),
];
export const minutesOptions = [
  ...Array.from({ length: 60 }, (_, i) => ({ value: i + 1, label: i + 1 }))
];
export const secondsOptions = [
  ...Array.from({ length: 60 }, (_, i) => ({ value: i + 1, label: i + 1 })),
];
