import React from "react";
import "./profileihaveignore.css";

const ProfileIHaveIgnore = () => {
  return (
    <div>
      <div className="page-heading">
        <p>Profile I Have Ignore</p>
      </div>

      <div className="profile-have-container">
        <h1 className="profile-have-heading">Who Profile I Have Ignore</h1>
        <div className="profile-have-input-container-view">
          <input
            type="text"
            className="profile-have-input"
            placeholder="Name"
          />
          <input
            type="text"
            className="profile-have-input"
            placeholder="Profile ID"
          />
          <input
            type="text"
            className="profile-have-input"
            placeholder="Viewed Date"
          />
        </div>
      </div>
    </div>
  );
};

export default ProfileIHaveIgnore;
