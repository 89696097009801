import React from 'react'
import "./shortlistedbyme.css"

const ShortlistedByMe = () => {
  return (
    <div>

      <div className='profile-heading'>
        <p>Liked Profile</p>
      </div>
      
    
    

<div className="liked-profile-container">
      <h1 className="liked-profile-heading">Who Liked By Me</h1>
      <div className="liked-profile-input-container-view">
        <input type="text" className="liked-profile-input" placeholder="Name"  />
        <input type="text" className="liked-profile-input" placeholder="Profile ID" />
        <input type="text" className="liked-profile-input" placeholder="Viewed Date" />
      </div>
    </div>

    
  </div>
    
  )
}

export default ShortlistedByMe
