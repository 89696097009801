import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./Forgot Password Style.css";
import Swal from 'sweetalert2';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    // Get the email from the URL parameters
    const params = new URLSearchParams(window.location.search);
    const emailFromURL = params.get('email');

    // Set the email in the component state
    if (emailFromURL) {
      setEmail(emailFromURL);
    }
  }, []);

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    // Check if email is empty
    if (!email) {
      Swal.fire({
        title: "Error!",
        text: "Please enter your email address first.",
        icon: "error",
      });
      return; // Exit the function if email is empty
    }

    const apiUrl = "https://madmin.hola9.com/api/user/send-reset-password-email/";
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Cookie': 'csrftoken=SaICScpFs727gHmISrHEROCdmTkxigWF',
        },
        body: `email=${email}`,
        redirect: 'follow',
      });
      console.log(response);
      
      // Check if the response is ok
      if (response.ok) {
        console.log(`Forgot password request for email: ${email}`);
        Swal.fire({
          title: "Link has been sent!",
          text: "Check your registered email!",
          icon: "success"
        });
        setEmail(''); // Clear email input
      } else {
        Swal.fire({
          title: "Failed!",
          text: "Please enter a registered email!",
          icon: "error"
        });
      }
    } catch (error) {
      console.error('Error:', error);
      Swal.fire({
        title: "Failed!",
        text: "An error occurred while sending the request.",
        icon: "error"
      });
    }
  };

  return (
    <div>
      <section className="page-title" style={{ backgroundColor: "black", marginTop: "93px" }}>
        <div className="auto-container-forgot">
          <h1 className="forgot-title">Forgot Password</h1>
        </div>
      </section>
      <div className="auto-container-forgot">
        <div className="envelope-image-forgot" />
        <div className="contact-form-forgot">
          <div className="form-inner-forgot">
            <div className="upper-box-forgot">
              <div className="sec-title-forgot text-center-forgot">
                <br />
                <div className="text-forgot">
                  Please enter your email address to retrieve your password..
                </div>
              </div>
            </div>
            <center>
              <form
                method="post"
                action="forgot_password_submit"
                id="contact-form"
                noValidate="novalidate"
                onSubmit={handleForgotPassword}
              >
                <div className="row-forgot clearfix-forgot">
                  <div className="col-lg-2-forgot col-md-4-forgot col-sm-4-forgot"></div>
                  <div className="col-lg-8-forgot col-md-5-forgot col-sm-8-forgot form-group-forgot" id="contact-form">
                    <label className='forgot-email'>Email:</label>
                    <input
                      className='forgot-input'
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      style={{ width: "38%" }}
                      placeholder="Enter email address"
                    />
                    <br />
                    <div className="btn-box-forgot">
                      <button
                        className="theme-btn-forgot btn-forgot btn-style-one-forgot mb-3-forgot"
                        type="submit"
                        name="submit"
                      >
                        <span className="btn-title-forgot">
                          Submit
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </center>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
