import React from "react";
import "./ViewedContactList.css";

const ViewedContactList = () => {
  return (
    <div>
      <div className="page-title">
        <p>Viewed Contact List</p>
      </div>
      <div className="viwed-contact-container">
        
          <h1 className="viwed-contact-heading">Who Viewed Contact List</h1>
          <div className="viwed-contact-input-container-view">
            <input
              type="text"
              className="viwed-contact-input"
              placeholder="Name"
            />
            <input
              type="text"
              className="viwed-contact-input"
              placeholder="Profile ID"
            />
            <input
              type="text"
              className="viwed-contact-input"
              placeholder="Viewed Date"
            />
          </div>
        
      </div>
    </div>
  );
};

export default ViewedContactList;
