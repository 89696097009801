import React from "react";
import "./RequestPending.css";

const RequestPending = () => {
  return (
    <div>
      <div className="page-title">
        <p>Request Pending</p>
      </div>
      <div className="msg-container">
        <h1>Oop's</h1>
        <h4>Sorry Result Not Found</h4>
        <p>You have not yet connected profiles.</p>
        <a href="smart_search" className="theme-btn btn-style-three">
          <span className="btn-title">Search</span>
        </a>
        {/* <div className="anim-icons full-width" style={{ marginTop: "100px" }}>
            <span
              className="icon icon-circle-blue wow fadeIn animated"
              style={{
                visibility: "visible",
                animationName: "fa-spin",
                marginTop: "300px ",
                marginLeft: "-790px",
              }}
            />
            <span
              className="icon icon-dots wow fadeInleft animated"
              style={{
                visibility: "visible",
                marginTop: "290px ",
                marginLeft: "-390px",
              }}
            />
            <span
              className="icon icon-line-1 wow fadeIn animated"
              style={{
                visibility: "visible",
                animationName: "fa-spin",
                marginTop: "320px ",
                marginLeft: "400px",
              }}
            />

            <span
              className="icon icon-circle-1 wow zoomIn animated"
              style={{
                visibility: "visible",
                animationName: "zoomIn",
                marginTop: "-150px ",
                marginLeft: "400px",
              }}
            />
          </div> */}
      </div>
    </div>
  );
};

export default RequestPending;
